import "babel-polyfill";
import "isomorphic-fetch";
import bootstrapjs from "./../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.min.js";
import plogin from "./pages/p-login/p-login.js";
import presetpassword from "./pages/p-reset-password/p-reset-password.js";
import completepresetpassword from "./pages/p-complete-reset-password/p-complete-reset-password.js";
import registeraccount from "./pages/p-register-account/p-register-account.js";
import pcreatead from "./pages/p-create-announcement/p-create-announcement.js";
import pchangeuserinformation from "./pages/p-change-user-info/p-change-user-info.js";
import dotdotdot from "./components/c-dotdotdot/c-dotdotdot.js";
import jquerydotdotdot from "./components/c-dotdotdot/jquery.dotdotdot.js";
import UsabilitySkipContent from "./components/c-skip-content/c-skip-content";
import component_spinner from "./components/c-spinner/c-spinner";
import component_navbar from "./components/c-navbar/c-navbar";
import componentMobileMenu from "./components/c-mobile-menu/c-mobile-menu";
import componentAccordion from "./components/c-accordion/c-accordion";
import componentPreviewModal from "./components/c-preview-modal/c-preview-modal";



module.exports = (function () {
  jQuery(document).ready(function () {
    UsabilitySkipContent.init();
    component_spinner.init();
    component_navbar.init();
    componentMobileMenu.init();
    componentAccordion.init();
    componentPreviewModal.init();
  });
})();
