/**
 * MultiselectDropdown
 */

import React from "react";
import MultiselectDropdownOption from "./c-multi-select-dropdown-option";

/** Multiselect dropdown component. */
export default class MultiselectDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleOptions: false,
      selectedBuckets: null,
      searchQuery: "",
    };
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleOptionCheck = this.handleOptionCheck.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    let dropdownRef = document.getElementById("multiselect-dropdown");
    dropdownRef.addEventListener("blur", this.handleBlur);
    document.addEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedBuckets !== this.props.selectedBuckets) {
      this.setState({
        selectedBuckets: this.props.selectedBuckets,
      });
    }
  }

  render() {
    return (
      <fieldset
        id="multiselect-dropdown"
        aria-describedby={this.props.ariaDescribedby}
        className="multi-select-dropdown"
      >
        <legend className="multi-select-dropdown__legend">
          {this.props.legend}{" "}
          {this.props.abbr && <abbr title="Obligatoriskt">*</abbr>}
        </legend>
        <div className="multi-select-dropdown__button-wrapper">
          <button
            onClick={this.handleToggleDropdown}
            aria-expanded={this.state.visibleOptions}
            aria-controls={`${this.props.id}-dropdown`}
            type="button"
            data-button-action={this.props.legend}
            className={`multi-select-dropdown__button ${
              this.props.invalid && "multi-select-dropdown__button--invalid"
            }`}
            disabled={this.props.disabled}
            data-input-type={this.props.dataInputType}
          >
            <span
              className={`multi-select-dropdown__button__text ${
                this.state.visibleOptions &&
                "multi-select-dropdown__button__text--black"
              }`}
            >
              {this.props.buttonSrText && (
                <span className="sr-only">{this.props.buttonSrText}</span>
              )}
              {this.props.buttonText}
            </span>
            <span>
              {!!this.state.selectedBuckets &&
                this.state.selectedBuckets > 0 && (
                  <span className="multi-select-dropdown__dropdown__counter">
                    {this.state.selectedBuckets}
                  </span>
                )}
              <span className="fa fa-chevron-down"></span>
            </span>
          </button>
        </div>
        <div
          hidden={!this.state.visibleOptions}
          id={`${this.props.id}-dropdown`}
          className="multi-select-dropdown__dropdown"
        >
          {this.props.hasSearchField && (
            <div className="multi-select-dropdown__search">
              <label
                className="sr-only"
                htmlFor={`filter-search-${this.props.id}`}
              >
                {this.props.searchPlaceholder}
              </label>
              <input
                type="text"
                id={`filter-search-${this.props.id}`}
                name={`filter-search-${this.props.id}`}
                placeholder={this.props.searchPlaceholder}
                value={this.state.searchQuery}
                onChange={(e) => this.setState({ searchQuery: e.target.value })}
                autoComplete="off"
                className="multi-select-dropdown__search__input"
              />
              <button
                className="multi-select-dropdown__search__button"
                type="button"
                onClick={() => {
                  this.setState({
                    searchQuery: this.state.searchQuery
                      ? ""
                      : this.state.searchQuery,
                  });
                }}
              >
                <span className="sr-only">
                  {this.state.searchQuery ? "Rensa sökfält" : "Sök"}
                </span>
                {this.state.searchQuery ? (
                  <span className="fa fa-remove"></span>
                ) : (
                  <span className="fa fa-search"></span>
                )}
              </button>
            </div>
          )}
          <div className="multi-select-dropdown__dropdown__wrapper">
            {this.props.options &&
              this.props.options.length > 0 &&
              this.props.options.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={
                      !option.text
                        .toLowerCase()
                        .includes(this.state.searchQuery.toLowerCase()) &&
                      "multi-select-dropdown__dropdown__option--hidden"
                    }
                  >
                    <MultiselectDropdownOption
                      id={`option-id-${option.value}`}
                      value={option.value}
                      isChecked={option.selected}
                      onChange={this.handleOptionCheck}
                    >
                      {option.text}
                    </MultiselectDropdownOption>
                  </div>
                );
              })}
          </div>
        </div>
      </fieldset>
    );
  }

  handleClickOutside(e) {
    let dropdownRef = document.getElementById("multiselect-dropdown");
    if (dropdownRef && !dropdownRef.contains(e.target)) {
      this.setState({
        visibleOptions: false,
      });
    }
  }

  handleBlur() {
    console.log("blur");
    this.setState({
      visibleOptions: false,
    });
  }

  handleToggleDropdown() {
    this.setState({
      visibleOptions: !this.state.visibleOptions,
    });
  }

  handleOptionCheck(value) {
    this.props.handleOptionChange(value);
  }
}
