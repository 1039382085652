import jQuery from "jquery";

let component_navbar = {
  init() {
    $("body").on("click", function (event) {
      const isExpanded = $("#user-button").attr("aria-expanded");
      const userButton = document.getElementById("user-button");

      if (userButton) {
        // Click outside - hide dropdown
        if (isExpanded === "true" && !userButton.contains(event.target)) {
          $("#user-dropdown").removeClass("is-visible");
          $("#user-button").attr("aria-expanded", false);
        }
        // Toggle dropdown by button
        else if (userButton.contains(event.target)) {
          if (isExpanded === "true") {
            $("#user-dropdown").removeClass("is-visible");
            $("#user-button").attr("aria-expanded", false);
          } else {
            $("#user-dropdown").addClass("is-visible");
            $("#user-button").attr("aria-expanded", true);
          }
        }
      }
    });
  },
};

export default component_navbar;
