import React from "react";
import ReactDOM from "react-dom";
import Validation from "react-validation";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import {
  isEmail,
  isAlpha,
  isNumeric,
  isPasswordStrengthOk,
  isPhone,
} from "../../validator";

require("es6-promise").polyfill();

export default class ChangeUserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      mailFocus: "",
      mailValid: "",
      currentPassword: "",
      currentPasswordFocus: "",
      currentPasswordValid: "",
      newPassword: "",
      newPasswordFocus: "",
      newPasswordValid: "",
      confirmPassword: "",
      confirmPasswordFocus: "",
      confirmPasswordValid: "",
      firstName: "",
      lastName: "",
      role: "",
      firstNameFocus: "",
      lastNameFocus: "",
      firstNameValid: "",
      lastNameValid: "",
      phoneNumber: "",
      phoneNumberFocus: "",
      phoneNumberValid: "",
      errorMessage: "",
      infoMessage: "",
    };

    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleCurrentPasswordChange =
      this.handleCurrentPasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleMailFocus = this.handleMailFocus.bind(this);
    this.handleFirstNameFocus = this.handleFirstNameFocus.bind(this);
    this.handleLastNameFocus = this.handleLastNameFocus.bind(this);
    this.handleCurrentPasswordFocus =
      this.handleCurrentPasswordFocus.bind(this);
    this.handleNewPasswordFocus = this.handleNewPasswordFocus.bind(this);
    this.handleConfirmPasswordFocus =
      this.handleConfirmPasswordFocus.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handlePhoneNumberFocus = this.handlePhoneNumberFocus.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    this.getUser();
  }

  render() {
    return (
      <div>
        <section className="page-wrapper">
          <Validation.components.Form
            onSubmit={(event) => this.handleSave(event)}
            ref={(c) => {
              this.form = c;
            }}
          >
            <fieldset className="change-user-info-page__fieldset">
              <legend className="change-user-info-page__legend">
                Ändra dina uppgifter
              </legend>
              <div className="row">
                <div className="col-sm-7">
                  {this.state.infoMessage && (
                    <div className="c-alert c-alert--success">
                      <i className="fa fa-check" aria-hidden="true"></i>
                      {this.state.infoMessage}
                    </div>
                  )}
                  {this.state.errorMessage && (
                    <div className="c-alert c-alert--danger">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {this.state.errorMessage}
                    </div>
                  )}
                  <InputReactWithBootstrap
                    onChange={this.handleFirstNameChange}
                    onFocus={this.handleFirstNameFocus}
                    onBlur={this.handleUnfocus}
                    label="Förnamn"
                    value={this.state.firstName}
                    type="input"
                    name="firstName"
                    className="form__input"
                    id="firstName"
                    focus={this.state.firstNameFocus}
                    valid={this.state.firstNameValid}
                    validations={["required", "alpha"]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleLastNameChange}
                    onFocus={this.handleLastNameFocus}
                    onBlur={this.handleUnfocus}
                    label="Efternamn"
                    value={this.state.lastName}
                    type="input"
                    name="lastName"
                    className="form__input"
                    id="lastName"
                    focus={this.state.lastNameFocus}
                    valid={this.state.lastNameValid}
                    validations={["required", "alpha"]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <div className="form__element-wrapper">
                    <label htmlFor="role-select" className="form__label">
                      Roll
                    </label>
                    <select
                      id="role-select"
                      placeholder="Välj&hellip;"
                      value={this.state.role}
                      onChange={this.handleRoleChange}
                      defaultValue={this.state.role}
                      className="form__select"
                    >
                      {window.changeuserinfo.roles &&
                        window.changeuserinfo.roles.map((role) => {
                          return (
                            <option value={role.value} key={role.value}>
                              {role.text}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleMailChange}
                    onFocus={this.handleMailFocus}
                    onBlur={this.handleUnfocus}
                    label="E-postadress"
                    value={this.state.mail}
                    type="input"
                    name="email"
                    className="form__input"
                    id="mail"
                    focus={this.state.mailFocus}
                    valid={this.state.mailValid}
                    validations={["required", "email"]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handlePhoneNumberChange}
                    onFocus={this.handlePhoneNumberFocus}
                    onBlur={this.handleUnfocus}
                    label="Telefonnummer"
                    value={this.state.phoneNumber}
                    type="input"
                    name="phoneNumber"
                    className="form__input"
                    id="phoneNumber"
                    focus={this.state.phoneNumberFocus}
                    valid={this.state.phoneNumberValid}
                    validations={["phone"]}
                  />
                </div>
              </div>
            </fieldset>

            <fieldset className="change-user-info-page__fieldset">
              <legend className="change-user-info-page__legend">
                Byt lösenord
              </legend>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleCurrentPasswordChange}
                    onFocus={this.handleCurrentPasswordFocus}
                    onBlur={this.handleUnfocus}
                    label="Nuvarande lösenord"
                    value={this.state.currentPassword}
                    type="password"
                    name="passwordCurrent"
                    className="form__input"
                    id="currentpassword"
                    focus={this.state.currentPasswordFocus}
                    valid={this.state.currentPasswordValid}
                    validations={[]}
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleNewPasswordChange}
                    onFocus={this.handleNewPasswordFocus}
                    onBlur={this.handleUnfocus}
                    label="Nytt lösenord"
                    value={this.state.newPassword}
                    type="password"
                    name="password"
                    className="form__input"
                    id="newpassword"
                    focus={this.state.newPasswordFocus}
                    valid={this.state.newPasswordValid}
                    validations={["password", "passwordStrength"]}
                    autocomplete="new-password"
                  />
                  <div className="c-alert c-alert--info">
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                    Ditt lösenord ska innehålla minst 8 tecken varav en stor
                    bokstav.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleConfirmPasswordChange}
                    onFocus={this.handleConfirmPasswordFocus}
                    onBlur={this.handleUnfocus}
                    label="Bekräfta nytt lösenord"
                    value={this.state.confirmPassword}
                    type="password"
                    name="passwordConfirm"
                    className="form__input"
                    id="passwordConfirm"
                    focus={this.state.confirmPasswordFocus}
                    valid={this.state.confirmPasswordValid}
                    validations={["password"]}
                    autocomplete="new-password"
                  />
                </div>
              </div>
            </fieldset>
            <div className="row">
              <div className="col-xs-12 change-user-info-page__buttons-wrapper">
                <a
                  href={window.changeuserinfo.mypageurl}
                  className="btn-secondary"
                  tabIndex="0"
                >
                  Avbryt
                </a>
                <input
                  type="submit"
                  value="Spara"
                  className="btn uhm-petrol"
                  tabIndex="0"
                />
              </div>
            </div>
          </Validation.components.Form>
        </section>
      </div>
    );
  }

  handleMailChange(inputMail) {
    this.setState({
      mail: inputMail.target.value,
    });
    if (this.state.mail && !this.state.mailFocus) {
      this.setState({
        mailValid: "-valid",
      });
    } else {
      this.setState({
        mailValid: "",
      });
    }
  }

  handleCurrentPasswordChange(inputCurrentPassword) {
    this.setState({
      currentPassword: inputCurrentPassword.target.value,
    });
    if (this.state.currentPassword && !this.state.currentPasswordFocus) {
      this.setState({
        currentPasswordValid: "-valid",
      });
    } else {
      this.setState({
        currentPasswordValid: "",
      });
    }
  }

  handleNewPasswordChange(inputNewPassword) {
    this.setState({
      newPassword: inputNewPassword.target.value,
    });
    if (this.state.newPassword && !this.state.newPasswordFocus) {
      this.setState({
        newPasswordValid: "-valid",
      });
    } else {
      this.setState({
        newPasswordValid: "",
      });
    }
  }

  handleConfirmPasswordChange(inputConfirmPassword) {
    this.setState({
      confirmPassword: inputConfirmPassword.target.value,
    });
    if (this.state.confirmPassword && !this.state.confirmPasswordFocus) {
      this.setState({
        confirmPasswordValid: "-valid",
      });
    } else {
      this.setState({
        confirmPasswordValid: "",
      });
    }
  }

  handleFirstNameChange(inputFirstName) {
    this.setState({
      firstName: inputFirstName.target.value,
    });
    if (this.state.firstName && !this.state.firstNameFocus) {
      this.setState({
        firstNameValid: "-valid",
      });
    } else {
      this.setState({
        firstNameValid: "",
      });
    }
  }

  handleLastNameChange(inputLastName) {
    this.setState({
      lastName: inputLastName.target.value,
    });
    if (this.state.lastName && !this.state.lastNameFocus) {
      this.setState({
        lastNameValid: "-valid",
      });
    } else {
      this.setState({
        lastNameValid: "",
      });
    }
  }

  handleRoleChange(selectedOption) {
    this.setState({
      role: selectedOption.target.value,
    });
  }

  handlePhoneNumberChange(inputPhoneNumber) {
    this.setState({
      phoneNumber: inputPhoneNumber.target.value,
    });
    if (this.state.phoneNumber && !this.state.phoneNumberFocus) {
      this.setState({
        phoneNumberValid: "-valid",
      });
    } else {
      this.setState({
        phoneNumberValid: "",
      });
    }
  }

  handleMailFocus() {
    this.setState({
      mailFocus: "-focus",
      newMailFocus: "",
      newPasswordFocus: "",
      currentPasswordFocus: "",
      confirmPasswordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleCurrentPasswordFocus() {
    this.setState({
      currentPasswordFocus: "-focus",
      newPasswordFocus: "",
      confirmPasswordFocus: "",
      mailFocus: "",
      currentMailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleNewPasswordFocus() {
    this.setState({
      newPasswordFocus: "-focus",
      currentPasswordFocus: "",
      confirmPasswordFocus: "",
      mailFocus: "",
      newMailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleConfirmPasswordFocus() {
    this.setState({
      newPasswordFocus: "",
      currentPasswordFocus: "",
      confirmPasswordFocus: "-focus",
      mailFocus: "",
      newMailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleFirstNameFocus() {
    this.setState({
      firstNameFocus: "-focus",
      currentPasswordFocus: "",
      mailFocus: "",
      newMailFocus: "",
      newPasswordFocus: "",
      confirmPasswordFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleLastNameFocus() {
    this.setState({
      lastNameFocus: "-focus",
      currentPasswordFocus: "",
      mailFocus: "",
      newMailFocus: "",
      newPasswordFocus: "",
      confirmPasswordFocus: "",
      firstNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handlePhoneNumberFocus() {
    this.setState({
      phoneNumberFocus: "-focus",
      currentPasswordFocus: "",
      mailFocus: "",
      newMailFocus: "",
      newPasswordFocus: "",
      confirmPasswordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
    });
  }

  handleUnfocus() {
    this.setState({
      mailFocus: "",
      newPasswordFocus: "",
      confirmPasswordFocus: "",
      currentPasswordFocus: "",
      newMailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  getUser() {
    fetch(window.location.pathname + "/GetUser/", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status >= 300) {
          console.log("ErrorResponse", response);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          firstName: data.FirstName || "",
          lastName: data.LastName || "",
          mail: data.Email || "",
          phoneNumber: data.Phone || "",
          role: this.getSelectedRole(),
        });
        return data;
      });
  }

  getSelectedRole() {
    let selectedRole = window.changeuserinfo.roles.find(
      (role) => role.selected === true
    );
    if (selectedRole) {
      return selectedRole.value;
    } else {
      return window.changeuserinfo.roles[0].value;
    }
  }

  handleSave(event) {
    event.preventDefault();

    const validationResult = this.form.validateAll();

    if (Object.keys(validationResult).length === 0) {
      let newUser = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        advertizerRoleId: this.state.role,
        mail: this.state.mail,
        phoneNumber: this.state.phoneNumber,
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
      };
      this.saveData(newUser);
    } else {
      this.setState({
        errorMessage: window.changeuserinfo.errorValidation,
        infoMessage: "",
        loading: false,
      });
      this.scrollToTop();
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  //post
  saveData(form) {
    if (!!form) {
      fetch(window.location.pathname + "/SaveUser", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.IsError || result.status >= 300) {
            this.setState({
              errorMessage: result.Error
                ? result.Error
                : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
              infoMessage: "",
            });
          } else {
            this.setState({
              infoMessage: result.Info,
              errorMessage: "",
            });
          }
          this.scrollToTop();
        })
        .catch((error) => {
          this.setState({
            errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
            infoMessage: "",
          });
          this.scrollToTop();
        });
    }
  }
}

if (document.getElementById("change-user-information")) {
  ReactDOM.render(
    <ChangeUserInfo />,
    document.getElementById("change-user-information")
  );
}
