import jQuery from "jquery";

let component_mobile_menu = {
  init() {
    $("body").addClass("mobile-menu__overlay");

    let menuButton = $("#mobile-menu-button");
    let menuPane = $("#mobile-menu-pane");

    menuButton.on("click", function () {
      menuPane.toggle(0, function () {
        const isExpanded = menuButton.attr("aria-expanded");
        if (isExpanded === "true") {
          menuButton.attr("aria-expanded", false);
          menuButton.children().html("").addClass("fa fa-navicon");
          $("body").removeClass("mobile-menu--open");
        } else {
          menuButton.attr("aria-expanded", true);
          menuButton
            .children(".fa-navicon")
            .removeClass("fa fa-navicon")
            .html("&times;");
          $("body").addClass("mobile-menu--open");
        }
      });
    });

    let userButton = $("#mobile-menu-user-button");
    let userDropdown = $("#mobile-menu-user-dropdown");

    userButton.on("click", function () {
      userDropdown.toggle(0, function () {
        const isExpanded = userButton.attr("aria-expanded");
        if (isExpanded === "true") {
          userButton.attr("aria-expanded", false);
          userButton.children('.plus-minus').html("&plus;");
        } else {
          userButton.attr("aria-expanded", true);
          userButton.children('.plus-minus').html("&minus;");
        }
      });
    });
  },
};

export default component_mobile_menu;
