import jQuery from 'jquery';

let component_niceInput = {

    init() {
        
        $('.nice-input input.question').each(function(){
            if ($(this).val()){
                $(this).closest('.collapse').collapse('show');
            }
        });
    }

};

export default component_niceInput;