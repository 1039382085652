import React from "react";
import ReactDOM from "react-dom";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import Validation from "react-validation";
import { isEmail } from "../../validator";
require("es6-promise").polyfill();

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMail: true,
      mail: "",
      mailFocus: "",
      mailValid: "",
      errorMessage: "",
      infoMessage: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
    this.handleMailFocus = this.handleMailFocus.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
  }

  render() {
    return (
      <div>
        <section className="page-wrapper">
          <div className="page-wrapper__layout">
            <div className="row">
              <div className="col-md-7">
                <Validation.components.Form
                  ref={(c) => {
                    this.form = c;
                  }}
                  onSubmit={(event) => this.handleSubmit(event)}
                >
                  <div className="margin-bottom-30">
                    <h2>Glömt lösenordet</h2>
                    <p>
                      Fyll i den e-postadress som du valde vid registreringen
                      och klicka på knappen Skicka mejl. Ett e-postmeddelande
                      skickas till dig med en länk där du kan byta lösenord.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      {this.state.infoMessage && (
                        <div className="c-alert c-alert--success">
                          <i className="fa fa-check" aria-hidden="true"></i>
                          {this.state.infoMessage}
                        </div>
                      )}
                      {this.state.errorMessage && (
                        <div className="c-alert c-alert--danger">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {this.state.errorMessage}
                        </div>
                      )}
                      <InputReactWithBootstrap
                        onChange={this.handleMailChange}
                        onFocus={this.handleMailFocus}
                        onBlur={this.handleUnfocus}
                        label="E-post"
                        value={this.state.mail}
                        type="input"
                        name="email"
                        className="form__input"
                        id="mail"
                        focus={this.state.mailFocus}
                        valid={this.state.mailValid}
                        validations={["required", "email"]}
                        required="true"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 reset-password-page__button-wrapper">
                      <a
                        href={window.resetpassword.apiurl}
                        className="btn-secondary"
                        tabIndex="0"
                      >
                        Tillbaka
                      </a>
                      <input
                        type="submit"
                        value="Skicka mejl"
                        className="btn uhm-petrol"
                        tabIndex="0"
                      />
                    </div>
                  </div>
                </Validation.components.Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  //Handlers --------------------------------------------------------------------------------------------------

  handleMailChange(inputMail) {
    this.setState({
      mail: inputMail.target.value,
    });
    if (this.state.mail && !this.state.mailFocus) {
      this.setState({
        mailValid: "-valid",
      });
    } else {
      this.setState({
        mailValid: "",
      });
    }
  }

  handleMailFocus() {
    this.setState({
      mailFocus: "-focus",
    });
  }

  handleUnfocus() {
    this.setState({
      mailFocus: "",
    });

    if (
      this.state.newMail &&
      isEmail(this.state.newMail) &&
      this.state.loadMail
    ) {
      this.getInformationByMail(this.state.newMail);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (isEmail(this.state.mail)) {
      fetch(window.resetpassword.apiurl + "resetpassword", {
        body: JSON.stringify({
          email: this.state.mail,
          __RequestVerificationToken: window.resetpassword.antiforgery,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        method: "POST",
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.IsError || result.status >= 300) {
            this.setState({
              errorMessage: result.Error
                ? result.Error
                : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
              infoMessage: "",
            });
          } else {
            this.setState({
              infoMessage: result.Info,
              errorMessage: "",
            });
          }
        })
        .catch((error) => {
          this.setState({
            errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
            infoMessage: "",
          });
        });
    }
  }
}

const resetPasswordDiv = document.getElementById("resetpassword");
if (resetPasswordDiv) {
  ReactDOM.render(<ResetPassword />, resetPasswordDiv);
}
