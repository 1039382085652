import jQuery from "jquery";

let component_accordion = {
  init() {
    let accordions = $('*[data-toggle="accordion"]');
    let accordionContents = $(".accordion__content");

    accordionContents.each((index, content) => {
      $(content).attr("id", `accordion-content-${index}`);
    });

    accordions.each((index, accordion) => {
      $(accordion).attr("id", `accordion-${index}`);
      $(accordion).attr("aria-controls", `accordion-content-${index}`);
      $(accordion).on("click", function () {
        let accordionContentId = $(accordion).attr("aria-controls");
        let accordionContent = $(`#${accordionContentId}`);

        accordionContent.toggle(0, function () {
          const isExpanded = $(accordion).attr("aria-expanded");
          if (isExpanded === "true") {
            $(accordion).attr("aria-expanded", false);
            $(accordion)
              .find(".accordion__icon")
              .removeClass("accordion__icon--minus")
              .addClass("accordion__icon--plus");
          } else {
            $(accordion).attr("aria-expanded", true);
            $(accordion)
              .find(".accordion__icon")
              .removeClass("accordion__icon--plus")
              .addClass("accordion__icon--minus");
          }
        });
      });
    });
  },
};

export default component_accordion;
