import React from "react";
import ReactDOM from "react-dom";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/sv";
import Validation from "react-validation";
import component_filterCheckbox from "../../components/c-filter-checkbox/c-filter-checkbox";
import component_niceInput from "../../components/c-nice-input/c-nice-input";
import MultiselectDropdown from "../../components/c-multi-select-dropdown/c-multi-select-dropdown.js";

require("es6-promise").polyfill();

let formChanged = false;

export default class CreateAd extends React.Component {
  constructor(props) {
    super(props);

    var urlVars = getUrlVars();
    var id = urlVars["id"];
    var isAdmin = urlVars["isAdmin"];

    this.state = {
      isAdmin: isAdmin,
      id: id,
      header: "",
      systemStartDate: moment(),
      systemStartDateRaw: moment(),
      systemEndDate: moment(),
      systemEndDateRaw: moment(),
      systemEndDateOption: 0,
      description: "",
      businessAreas: [],
      selectedBusinessAreas: [],
      noBusinessAreaMatchedSelected: true,
      inqueryUrl: "",
      inqueryUpdated: moment(),
      inqueryUpdatedRaw: moment(),
      name: "",
      phoneNumber: "",
      mail: "",
      additionalName: "",
      additionalPhoneNumber: "",
      additionalMail: "",
      registrationNr: "",
      requirements: [],
      selectedRequirements: [],
      adState: "", // "" | "PUBLISHED" | "DRAFT"
      additionalContactActive: false,
      logotypeUrl: "",
      regionType: "",
      regionName: "",
      selectedReviewMonth: 1,
      publishSucceed: false,

      headerFocus: "",
      systemStartDateFocus: "",
      systemEndDateFocus: "",
      descriptionFocus: "",
      inqueryUrlFocus: "",
      inqueryUpdatedFocus: "",
      nameFocus: "",
      phoneNumberFocus: "",
      mailFocus: "",
      additionalNameFocus: "",
      additionalPhoneNumberFocus: "",
      additionalMailFocus: "",
      registrationNrFocus: "",

      headerValid: "",
      systemStartDateValid: "",
      systemEndDateValid: "",
      descriptionValid: "",
      inqueryUrlValid: "",
      inqueryUpdatedValid: "",
      nameValid: "",
      phoneNumberValid: "",
      mailValid: "",
      additionalNameValid: "",
      additionalPhoneNumberValid: "",
      additionalMailValid: "",
      registrationNrValid: "",
      publishDate: moment(),

      errorMessage: "",
      publishLoading: false,
      saveDraftLoading: false,
      invalidFields: {},
      reviewMonths: [
        {
          text: "Januari",
          value: 1,
        },
        {
          text: "Februari",
          value: 2,
        },
        {
          text: "Mars",
          value: 3,
        },
        {
          text: "April",
          value: 4,
        },
        {
          text: "Maj",
          value: 5,
        },
        {
          text: "Juni",
          value: 6,
        },
        {
          text: "Juli",
          value: 7,
        },
        {
          text: "Augusti",
          value: 8,
        },
        {
          text: "September",
          value: 9,
        },
        {
          text: "Oktober",
          value: 10,
        },
        {
          text: "November",
          value: 11,
        },
        {
          text: "December",
          value: 12,
        },
      ],
    };

    /** Handle change */
    this.handleHeaderChange = this.handleHeaderChange.bind(this);
    this.handleSystemStartDateChange =
      this.handleSystemStartDateChange.bind(this);
    this.handleSystemStartDateRawChange =
      this.handleSystemStartDateRawChange.bind(this);
    this.handleSystemEndDateChange = this.handleSystemEndDateChange.bind(this);
    this.handleSystemEndDateRawChange =
      this.handleSystemEndDateRawChange.bind(this);
    this.handleSystemEndDateOptionChange =
      this.handleSystemEndDateOptionChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleBusinessAreaOptionChange =
      this.handleBusinessAreaOptionChange.bind(this);
    this.handleInqueryURLChange = this.handleInqueryURLChange.bind(this);
    this.handleInqueryUpdatedChange =
      this.handleInqueryUpdatedChange.bind(this);
    this.handleInqueryUpdatedRawChange =
      this.handleInqueryUpdatedRawChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleRegistrationNrChange =
      this.handleRegistrationNrChange.bind(this);
    this.handleRequirementsCheckboxChange =
      this.handleRequirementsCheckboxChange.bind(this);
    this.handleNoBusinessAreaMatched =
      this.handleNoBusinessAreaMatched.bind(this);

    /** Handle focus */
    this.handleHeaderFocus = this.handleHeaderFocus.bind(this);
    this.handleSystemStartDateFocus =
      this.handleSystemStartDateFocus.bind(this);
    this.handleSystemEndDateFocus = this.handleSystemEndDateFocus.bind(this);
    this.handleDescriptionFocus = this.handleDescriptionFocus.bind(this);
    this.handleInqueryURLFocus = this.handleInqueryURLFocus.bind(this);
    this.handleInqueryUpdatedFocus = this.handleInqueryUpdatedFocus.bind(this);
    this.handleNameFocus = this.handleNameFocus.bind(this);
    this.handlePhoneNumberFocus = this.handlePhoneNumberFocus.bind(this);
    this.handleMailFocus = this.handleMailFocus.bind(this);
    this.handleRegistrationNrFocus = this.handleRegistrationNrFocus.bind(this);

    this.publishAd = this.publishAd.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.handleToggleAdditionalContact =
      this.handleToggleAdditionalContact.bind(this);

    this.handleUnfocus = this.handleUnfocus.bind(this);
  }

  componentDidMount() {
    /** Warning-pop-up before leaving page with unsaved changes */
    document
      .getElementById("formCreateEditAd")
      .addEventListener("change", () => (formChanged = true));
    window.onbeforeunload = this.onBeforeUnload;

    let startDateEl = document.getElementById("systemStartDate");
    let endDateEl = document.getElementById("systemEndDate");
    let inqueryUpdatedEl = document.getElementById("inqueryUpdated");
    startDateEl.setAttribute("data-input-type", "Start date");
    endDateEl.setAttribute("data-input-type", "End date");
    inqueryUpdatedEl.setAttribute("data-input-type", "Last date for update");

    var id = this.state.id == null ? 0 : this.state.id;
    var isAdmin = this.state.isAdmin == null ? 0 : 1;

    fetch("/api/sv/announcement/Get?id=" + id + "&isAdmin=" + isAdmin, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({
            errorMessage: window.createeditad.errorCreateForm,
          });
        }
      })
      .then((json) => {
        this.setState({
          header: json.title,
          systemStartDate: json.systemStartDate
            ? moment(json.systemStartDate)
            : moment(),
          systemStartDateRaw: json.systemStartDate
            ? moment(json.systemStartDate)
            : moment(),
          systemEndDate: json.systemEndDate
            ? moment(json.systemEndDate)
            : moment(),
          systemEndDateeRaw: json.systemEndDate
            ? moment(json.systemEndDate)
            : moment(),
          systemEndDateOption: json.systemEndDate ? 1 : 0,
          description: json.description,
          inqueryUrl: json.inqueryUrl,
          inqueryUpdated: json.inqueryUpdated
            ? moment(json.inqueryUpdated)
            : moment(),
          inqueryUpdatedRaw: json.inqueryUpdated
            ? moment(json.inqueryUpdated)
            : moment(),
          name: json.contactPerson,
          phoneNumber: json.phoneNumber,
          mail: json.emailAddress,
          additionalContactActive:
            json.contactPerson2 || json.phoneNumber2 || json.emailAddress2
              ? true
              : false,
          additionalName: json.contactPerson2,
          additionalPhoneNumber: json.phoneNumber2,
          additionalMail: json.emailAddress2,
          registrationNr: json.registryNo,
          requirements: json.requirements,
          selectedRequirements: json.selectedRequirements,
          logotypeUrl: json.logotypeUrl,
          regionType: json.regionType,
          regionName: json.regionName,

          adState: json.adState,
          businessAreas: json.businessAreas,
          selectedBusinessAreas:
            json.selectedBusinessAreas &&
            json.selectedBusinessAreas.length === 1 &&
            json.selectedBusinessAreas[0] === -1
              ? []
              : json.selectedBusinessAreas,
          noBusinessAreaMatchedSelected:
            json.selectedBusinessAreas &&
            json.selectedBusinessAreas.length === 1 &&
            json.selectedBusinessAreas[0] === -1,
          publishDate: json.publishDate ? moment(json.publishDate) : moment(),
        });

        component_filterCheckbox.init();
        component_niceInput.init();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.additionalName !== this.state.additionalName) {
      component_niceInput.init();
    }
  }

  render() {
    var isEditMode = this.state.id !== undefined && this.state.id.length > 0;
    var isAdminMode =
      this.state.isAdmin !== undefined || this.state.isAdmin == 0;

    return (
      <div>
        <Validation.components.Form
          ref={(e) => {
            this.formCreateEditAd = e;
          }}
          id="formCreateEditAd"
          className="form"
        >
          {(this.state.adState === "PUBLISHED" ||
            this.state.adState === "DRAFT") && (
            <div className="create-announcement-page__edit-section">
              <a href={`${window.createeditad.mypageurl}`}>
                <span className="fa fa-chevron-left"></span> Tillbaka
              </a>

              {this.state.adState === "PUBLISHED" ? (
                <div>
                  <h2>
                    {`Ändra annonsen `}"{this.state.header}"
                  </h2>
                  <p>
                    Nedan kan du ändra uppgifter i din publicerade annons. Du
                    kan välja att förhandsgranska annonsen innan du publicerar
                    ändringar eller avpublicerar den.
                  </p>
                </div>
              ) : (
                <div>
                  <h2>
                    {`Ändra utkastet `}"{this.state.header}"
                  </h2>
                  <p>
                    Nedan kan du ändra uppgifter i din annons. Du kan välja att
                    förhandsgranska annonsen innan du publicerar den.
                  </p>
                </div>
              )}
              <div>
                <button
                  className="btn-secondary"
                  data-toggle="modal"
                  data-target="#preview-modal-ad"
                  onClick={(e) => e.preventDefault()}
                  data-button-action="Preview"
                >
                  Förhandsgranska
                </button>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-sm-7">
              {this.state.errorMessage && (
                <div
                  className="c-alert c-alert--danger"
                  aria-live="polite"
                  id="error-message"
                >
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  ></i>
                  {this.state.errorMessage}
                </div>
              )}
            </div>
          </div>

          {/* -------- Om uppdraget -------- */}
          <fieldset className="create-announcement-page__fieldset">
            <legend className="create-announcement-page__legend">
              Om uppdraget
            </legend>
            <div className="form__element-wrapper">
              <h2>Rubrik</h2>
              <p id="header-text">
                För att annonsen ska vara tydlig och synlig håll den kortfattad.
                Rubriken ska helst inte innehålla LOV, valfrihetssystem eller
                ert organisationsnamn (denna info framgår ändå).
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleHeaderChange}
                    onFocus={this.handleHeaderFocus}
                    onBlur={this.handleUnfocus}
                    label="Skriv en rubrik"
                    value={this.state.header}
                    type="input"
                    name="header"
                    abbr={true}
                    className="form__input"
                    id="header"
                    focus={this.state.headerFocus}
                    valid={this.state.headerValid}
                    validations={["required"]}
                    ariaDescribedby="header-text"
                    dataInputType="Ad heading"
                  />
                </div>
              </div>
            </div>
            <div>
              <h2>Startdatum</h2>
              <p id="systemStartDate-text">
                Datumet för när valfrihetssystemet startades.
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <div className="form__element-wrapper">
                    <label className="form__label" htmlFor="systemStartDate">
                      <span>Skriv ett startdatum</span>{" "}
                      <abbr title="Obligatoriskt">*</abbr>
                    </label>
                    <DatePicker
                      onFocus={this.handleSystemStartDateFocus}
                      onBlur={this.handleUnfocus}
                      className={
                        this.state.systemStartDate
                          ? "form__input " + this.state.systemStartDateValid
                          : "form__input " + this.state.systemStartDateValid
                      }
                      id="systemStartDate"
                      locale="sv"
                      dateFormat="YYYY-MM-DD"
                      ariaDescribedBy="systemStartDate-text"
                      selected={this.state.systemStartDate}
                      startDate={this.state.systemStartDate}
                      onChangeRaw={this.handleSystemStartDateRawChange}
                      onChange={this.handleSystemStartDateChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2>Slutdatum</h2>
              <p id="systemEndDate-text">
                Att valfrihetssystemet gäller tills vidare är satt som standard.
                Men har ert valfrihetssystem ett fastslaget slutdatum kryssar ni
                i det och anger datum för detta.
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <fieldset>
                    <legend className="sr-only">Slutdatum</legend>
                    <div className="form__radio-wrapper">
                      <label htmlFor="radio-UntilFurtherNotice">
                        <input
                          id="radio-UntilFurtherNotice"
                          className="radio-input"
                          type="radio"
                          value={0}
                          checked={this.state.systemEndDateOption === 0}
                          onChange={this.handleSystemEndDateOptionChange}
                          name="endDateOption"
                        />
                        <span>Valfrihetssystemet gäller tills vidare</span>
                      </label>
                    </div>
                    <div className="form__radio-wrapper">
                      <label htmlFor="radio-EndDate">
                        <input
                          id="radio-EndDate"
                          className="radio-input"
                          type="radio"
                          value={1}
                          checked={this.state.systemEndDateOption === 1}
                          onChange={this.handleSystemEndDateOptionChange}
                          name="endDateOption"
                        />
                        <span>Valfrihetssystemet gäller till:</span>
                      </label>
                    </div>
                    <div className="form__element-wrapper">
                      <label className="form__label" htmlFor="systemEndDate">
                        <span>Skriv ett slutdatum</span>
                      </label>
                      <DatePicker
                        onFocus={this.handleSystemEndDateFocus}
                        onBlur={this.handleUnfocus}
                        className="form__input"
                        id="systemEndDate"
                        locale="sv"
                        dateFormat="YYYY-MM-DD"
                        ariaDescribedBy="systemEndDate-text"
                        selected={this.state.systemEndDate}
                        startDate={this.state.systemEndDate}
                        onChangeRaw={this.handleSystemEndDateRawChange}
                        onChange={this.handleSystemEndDateChange}
                        disabled={this.state.systemEndDateOption === 0}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div>
              <h2>Information om valfrihetssystemet</h2>
              <p id="description-text">
                Skriv relevant information om vad ert valfrihetssystem avser. En
                kort beskrivning av uppdraget och om det finns något viktigt att
                lyfta fram till leverantörer exempelvis specifika krav eller
                ekonomisk modell.
              </p>
              <div className="row">
                <div className="col-sm-9">
                  <InputReactWithBootstrap
                    onChange={this.handleDescriptionChange}
                    onFocus={this.handleDescriptionFocus}
                    onBlur={this.handleUnfocus}
                    label="Skriv vad valfrihetssystemet avser"
                    value={this.state.description}
                    type="textarea"
                    abbr={true}
                    name="description"
                    className="form__textarea"
                    id="description"
                    focus={this.state.descriptionFocus}
                    valid=""
                    validations={["required"]}
                    ariaDescribedby={"description-text"}
                    dataInputType="Information"
                  />
                </div>
              </div>
            </div>
            <div>
              <h2>Verksamhetsområde</h2>
              <p id="areaOfOrganisation-text">
                Ange här vilken eller vilka verksamhetsområden som är aktuella i
                valfrihetssystemet. Det vill säga vilken eller vilka områden som
                avses med uppdraget. Om ett förfrågningsunderlag omfattar flera
                områden ska verksamhetsområden för samtliga områden anges i
                annonsen.
              </p>
              <fieldset>
                <legend className="sr-only">Verksamhetsområde</legend>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="form__element-wrapper">
                      <MultiselectDropdown
                        legend="Ange verksamhetsområde"
                        buttonText="Verksamhetsområde"
                        abbr={true}
                        ariaDescribedby="areaOfOrganisation-text"
                        options={this.state.businessAreas}
                        handleOptionChange={this.handleBusinessAreaOptionChange}
                        disabled={this.state.noBusinessAreaMatchedSelected}
                        invalid={
                          this.state.invalidFields["businessAreas"]
                            ? true
                            : false
                        }
                        selectedBuckets={
                          this.state.selectedBusinessAreas.length
                        }
                        hasSearchField={true}
                        searchPlaceholder="Sök verksamhetsområde"
                        dataInputType="Business area"
                      />
                      {this.state.invalidFields["businessAreas"] && (
                        <span className="form__error-text">
                          Vänligen välj verksamhetsområde eller markera
                          checkboxen nedan om inget passar.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="form__checkbox-wrapper">
                      <label htmlFor="checkbox-noBusinessAreaMatched">
                        <input
                          id="checkbox-noBusinessAreaMatched"
                          type="checkbox"
                          value={-1}
                          onChange={this.handleNoBusinessAreaMatched}
                          checked={this.state.noBusinessAreaMatchedSelected}
                          name="noBusinessAreaMatched"
                          disabled={
                            this.state.selectedBusinessAreas &&
                            this.state.selectedBusinessAreas.length > 0
                          }
                        />
                        <span>
                          Det finns inget verksamhetsområde som passar
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </fieldset>
          {/* -------- Förfrågningsunderlag -------- */}
          <fieldset className="create-announcement-page__fieldset">
            <legend className="create-announcement-page__legend">
              Förfrågningsunderlag
            </legend>
            <div className="form__element-wrapper">
              <h2>Länk till förfrågningsunderlag</h2>
              <p id="inqueryUrl-text">
                Lägg in länk till ert förfrågningsunderlag för
                valfrihetssystemet. Länken ska leda direkt till den sidan där
                förfrågningsunderlaget finns publicerat.
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleInqueryURLChange}
                    onFocus={this.handleInqueryURLFocus}
                    onBlur={this.handleUnfocus}
                    label="Skriv in länken till förfrågningsunderlaget"
                    value={this.state.inqueryUrl}
                    type="input"
                    abbr={true}
                    name="inqueryUrl"
                    className="form__input"
                    id="inqueryUrl"
                    focus={this.state.inqueryUrlFocus}
                    valid={this.state.inqueryUrlValid}
                    validations={["required", "url"]}
                    ariaDescribedby="inqueryUrl-text"
                    dataInputType="Tender link"
                  />
                </div>
              </div>
            </div>
            <div className="form__element-wrapper">
              <h2>Datum senast uppdatering av förfrågningsunderlag</h2>
              <p id="inqueryUpdated-text">
                Ange datum för senast uppdatering eller ändring av
                valfrihetssystemets förfrågningsunderlag.
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <div className="form__element-wrapper">
                    <label className="form__label" htmlFor="systemEndDate">
                      <span>Skriv datum för senaste uppdateringen</span>{" "}
                      <abbr title="Obligatoriskt">*</abbr>
                    </label>
                    <DatePicker
                      onFocus={this.handleInqueryUpdatedFocus}
                      onBlur={this.handleUnfocus}
                      label="Skriv datum för senaste uppdateringen"
                      className="form__input"
                      id="inqueryUpdated"
                      locale="sv"
                      dateFormat="YYYY-MM-DD"
                      ariaDescribedBy="inqueryUpdated-text"
                      selected={this.state.inqueryUpdated}
                      startDate={this.state.inqueryUpdated}
                      onChangeRaw={this.handleInqueryUpdatedRawChange}
                      onChange={this.handleInqueryUpdatedChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          {/* -------- Kontakt -------- */}
          <fieldset className="create-announcement-page__fieldset">
            <legend className="create-announcement-page__legend">
              Kontakt
            </legend>
            <fieldset className="form__element-wrapper create-announcement-page__subfieldset">
              <legend>
                Kontaktperson <span className="sr-only">1</span>
              </legend>
              <p id="contact-1-text">
                Kontaktperson kan vara du som ansvarar för annonsen eller någon
                annan i er organisation.
                <span className="create-announcement-page__hint">
                  Tips! Leverantörer uppskattar att kunna komma i kontakt med
                  rätt person för frågor. Ange direkta uppgifter till
                  kontaktperson - inte växelnummer eller registrators
                  e-postadress.
                </span>
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleNameChange}
                    onFocus={this.handleNameFocus}
                    onBlur={this.handleUnfocus}
                    label="För- och efternamn"
                    value={this.state.name}
                    type="input"
                    name="name"
                    abbr={true}
                    className="form__input"
                    id="name"
                    focus={this.state.nameFocus}
                    valid={this.state.nameValid}
                    validations={["required"]}
                    ariaDescribedby="contact-1-text"
                    dataInputType="Contact name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handlePhoneNumberChange}
                    onFocus={this.handlePhoneNumberFocus}
                    onBlur={this.handleUnfocus}
                    label="Telefonnummer"
                    value={this.state.phoneNumber}
                    type="input"
                    abbr={true}
                    name="phone"
                    className="form__input"
                    id="phone"
                    focus={this.state.phoneNumberFocus}
                    valid={this.state.phoneNumberValid}
                    validations={["required", "phone"]}
                    dataInputType="Contact tel"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleMailChange}
                    onFocus={this.handleMailFocus}
                    onBlur={this.handleUnfocus}
                    label="E-post"
                    value={this.state.mail}
                    type="input"
                    name="email"
                    abbr={true}
                    className="form__input"
                    id="email"
                    focus={this.state.mailFocus}
                    valid={this.state.mailValid}
                    validations={["required", "email"]}
                    dataInputType="Contact email"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset
              className="form__element-wrapper create-announcement-page__subfieldset"
              id="additionalContactPerson"
              hidden={!this.state.additionalContactActive}
            >
              <legend>Ytterligare kontaktperson</legend>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleNameChange}
                    onFocus={this.handleNameFocus}
                    onBlur={this.handleUnfocus}
                    label="För- och efternamn"
                    value={this.state.additionalName}
                    type="input"
                    name="additionalName"
                    className="form__input"
                    id="additionalName"
                    focus={this.state.additionalNameFocus}
                    valid={this.state.addintionalNameValid}
                    validations={[]}
                    ariaDescribedby="contact-1-text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handlePhoneNumberChange}
                    onFocus={this.handlePhoneNumberFocus}
                    onBlur={this.handleUnfocus}
                    label="Telefonnummer"
                    value={this.state.additionalPhoneNumber}
                    type="input"
                    name="additionalPhone"
                    className="form__input"
                    id="additionalPhone"
                    focus={this.state.additionalPhoneNumberFocus}
                    valid={this.state.additionalPhoneNumberValid}
                    validations={["phone"]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleMailChange}
                    onFocus={this.handleMailFocus}
                    onBlur={this.handleUnfocus}
                    label="E-post"
                    value={this.state.additionalMail}
                    type="input"
                    name="additionalEmail"
                    className="form__input"
                    id="additionalEmail"
                    focus={this.state.additionalMailFocus}
                    valid={this.state.additionalMailValid}
                    validations={["emailNotRequired"]}
                  />
                </div>
              </div>
            </fieldset>
            <button
              className="btn-supportive"
              aria-controls="additionalContactPerson"
              onClick={this.handleToggleAdditionalContact}
            >
              {this.state.additionalContactActive ? (
                <span>
                  Ta bort ytterligare kontaktperson{" "}
                  <span className="symbol symbol--minus">_</span>
                </span>
              ) : (
                <span>
                  Lägg till ytterligare kontaktperson{" "}
                  <span className="symbol symbol--plus">+</span>
                </span>
              )}
            </button>
          </fieldset>
          {/* -------- Övrigt --------  */}
          <fieldset className="create-announcement-page__fieldset">
            <legend className="create-announcement-page__legend">Övrigt</legend>
            <div className="form__element-wrapper">
              <h2>Diarienummer</h2>
              <p id="registrationNr-text">
                Här kan du ange diarienumret för valfrihetssystemet.
              </p>
              <div className="row">
                <div className="col-sm-7">
                  <InputReactWithBootstrap
                    onChange={this.handleRegistrationNrChange}
                    onFocus={this.handleRegistrationNrFocus}
                    onBlur={this.handleUnfocus}
                    label="Diarienummer"
                    value={this.state.registrationNr}
                    type="input"
                    name="registrationNr"
                    className="form__input"
                    id="registrationNr"
                    focus={this.state.registrationNrFocus}
                    valid={this.state.registrationNrValid}
                    validations={[]}
                    ariaDescribedby="registrationNr-text"
                    dataInputType="Diary number"
                  />
                </div>
              </div>
            </div>
            <fieldset
              aria-describedby="requirements-text"
              className="form__element-wrapper create-announcement-page__subfieldset"
            >
              <legend>Krav</legend>
              <p id="requirements-text">
                Innehåller Valfrihetssystemet krav på något av följande? (Vi
                samlar in denna information för statistikändamål.)
              </p>
              <div className="row">
                <div className="col-sm-7">
                  {this.state.requirements.map((item, index) => {
                    return (
                      <div className="form__checkbox-wrapper" key={index}>
                        <label htmlFor={`checkbox-requirement-${item.value}`}>
                          <input
                            id={`checkbox-requirement-${item.value}`}
                            type="checkbox"
                            value={item.value}
                            onChange={(e) =>
                              this.handleRequirementsCheckboxChange(e, index)
                            }
                            name="requirements"
                            checked={item.selected}
                            dataInputType="Diary number"
                            data-input-type="Requirements"
                          />
                          <span>{item.text}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </fieldset>
          </fieldset>
          {/* -------- Buttons --------  */}
          <div className="create-announcement-page__buttons-wrapper">
            <button
              className="btn-secondary"
              data-toggle="modal"
              data-target="#preview-modal-ad"
              onClick={(e) => e.preventDefault()}
              data-button-action="Preview"
            >
              Förhandsgranska
            </button>
            {(this.state.adState === "DRAFT" || this.state.adState === "") && (
              <button
                className="btn-secondary"
                disabled={this.state.saveDraftLoading}
                onClick={(e) => this.onClickSaveDraft(e)}
                data-button-action="Save draft"
              >
                {this.state.saveDraftLoading ? "Sparar..." : "Spara utkast"}
              </button>
            )}
            {this.state.adState === "PUBLISHED" && (
              <button
                className="btn-secondary"
                data-toggle="modal"
                data-target="#unPublish"
                onClick={(e) => e.preventDefault()}
              >
                Avpublicera annons
              </button>
            )}
            <button
              className="btn uhm-petrol"
              type="submit"
              onClick={(e) => this.onClickPublish(e)}
              disabled={this.state.publishLoading}
              data-button-action="Publish ad"
            >
              {this.state.publishLoading
                ? "Publicerar..."
                : this.state.adState === "PUBLISHED"
                ? "Publicera ändringar"
                : "Publicera annons"}
            </button>
            <a
              className="create-announcement-page__to-top"
              href="#main-content"
            >
              Till toppen <span className="fa fa-chevron-up"></span>
            </a>
          </div>
        </Validation.components.Form>

        {/* Avpublicera annons - Modal */}
        <div
          className="modal fade"
          id="unPublish"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="myModalLabel">
                  Avpublicera annons?
                </h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Vill du verkligen avpublicera annonsen? Annonsen kommer inte
                  gå att publicera igen.
                </p>
              </div>
              <div className="modal-footer">
                <button className="btn-secondary" data-dismiss="modal">
                  Avbryt
                </button>
                <button
                  className="btn uhm-petrol"
                  onClick={(e) => this.onClickUnpublish(e)}
                >
                  Avpublicera
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Förhandsgranska - Modal */}
        <div
          className="preview-modal modal fade"
          id="preview-modal-ad"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="content-left">
                  <div>
                    {this.state.header && (
                      <h2 className="text-h1">{this.state.header}</h2>
                    )}
                    <p className="publish-date">{`Senast uppdaterad: ${moment(
                      new Date()
                    ).format("L")}`}</p>
                    {this.state.description && <p>{this.state.description}</p>}
                    {this.state.inqueryUrl && (
                      <a
                        className="btn uhm-petrol"
                        href={this.state.inqueryUrl}
                        target="_blank"
                      >
                        {`Till förfrågningsunderlag `}
                        <span className="fa fa-external-link"></span>
                      </a>
                    )}
                  </div>
                  <div className="notice-box">
                    Här kommer Upphandlingsmyndigheten lägga in en text.
                  </div>
                </div>
                <div className="content-right">
                  <img
                    src={this.state.logotypeUrl}
                    className="modal-logo"
                    alt="logga"
                  />
                  <dl className="description-list">
                    <dt className="property-text">{this.state.regionType}</dt>
                    <dd className="value-text">{this.state.regionName}</dd>
                    {(this.state.selectedBusinessAreas.length > 0 ||
                      this.state.noBusinessAreaMatchedSelected) && (
                      <dt className="property-text">Verksamhetsområde</dt>
                    )}
                    {this.state.selectedBusinessAreas.length > 0 && (
                      <dd className="value-text">
                        {this.state.selectedBusinessAreas.map(
                          (selectedAreaValue, index) => {
                            let item = this.state.businessAreas.find((area) => {
                              return area.value === selectedAreaValue;
                            });

                            if (item && index > 0) {
                              return `, ${item.text}`;
                            } else if (item && index === 0) {
                              return item.text;
                            }
                          }
                        )}
                      </dd>
                    )}
                    {this.state.noBusinessAreaMatchedSelected && (
                      <dd className="value-text">Ej angivet</dd>
                    )}
                    {this.state.systemStartDate && (
                      <dt className="property-text">Startdatum</dt>
                    )}
                    {this.state.systemStartDate && (
                      <dd className="value-text">
                        {moment(this.state.systemStartDate).format("L")}
                      </dd>
                    )}
                    <dt className="property-text">Slutdatum</dt>
                    <dd className="value-text">
                      {this.state.systemEndDateOption === 0 &&
                        "Gäller tills vidare"}
                      {this.state.systemEndDateOption === 1 &&
                        moment(this.state.systemEndDate).format("L")}
                    </dd>
                    {this.state.registrationNr && (
                      <dt className="property-text">Diarienummer</dt>
                    )}
                    {this.state.registrationNr && (
                      <dd className="value-text">
                        {this.state.registrationNr}
                      </dd>
                    )}
                  </dl>
                  {(this.state.name ||
                    this.state.phoneNumber ||
                    this.state.mail) && (
                    <h3 className="property-text">Kontaktperson</h3>
                  )}
                  <div className="contact-person">
                    <p className="value-text">{this.state.name}</p>
                    <p>
                      <a href={`tel:${this.state.phoneNumber}`}>
                        {this.state.phoneNumber}
                      </a>
                    </p>
                    <p>
                      <a href={`mailto: ${this.state.mail}`}>
                        {this.state.mail}
                      </a>
                    </p>
                  </div>
                  <div className="contact-person">
                    <p className="value-text">{this.state.additionalName}</p>
                    <p>
                      <a href={`tel:${this.state.additionalPhoneNumber}`}>
                        {this.state.additionalPhoneNumber}
                      </a>
                    </p>
                    <p>
                      <a href={`mailto: ${this.state.additionalMail}`}>
                        {this.state.additionalMail}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gransknings påminnelse - Modal */}
        <div
          className="modal review-reminder-modal fade"
          id="reviewReminder"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="myModalLabel">
                  Grattis! Din annons är publicerad!
                </h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h3>Vad händer nu?</h3>
                <p>
                  Vi vill gärna att du granskar din annons årligen. Detta för
                  att vi vill vara säkra om att informationen fortfarande
                  stämmer. Och för att potentiella leverantörer ska se att
                  annonsen hålls uppdaterad.
                </p>
                <p>
                  Om du inte gör detta själv kommer vi hjälpa dig att komma ihåg
                  genom att skicka en påminnelse.
                </p>
                <p>När på året vill du få en påminnelse?</p>
                <label htmlFor="review-month" className="sr-only">
                  {" "}
                  Månad
                </label>
                <div className="form-element-wrapper">
                  <select
                    id="review-month"
                    className="form__select"
                    onChange={(e) => {
                      this.setState({ selectedReviewMonth: e.target.value });
                    }}
                    defaultValue={this.state.selectedReviewMonth}
                    value={this.state.selectedReviewMonth}
                    disabled={this.state.publishSucceed}
                  >
                    {this.state.reviewMonths.map((item) => {
                      return <option value={item.value}>{item.text}</option>;
                    })}
                  </select>
                  <button
                    className="btn-secondary"
                    onClick={(e) => this.onClickReviewReminder(e)}
                    disabled={this.state.publishSucceed}
                  >
                    Tack, skicka påminnelse till mig under denna månad
                    {this.state.publishSucceed && (
                      <span className="fa fa-check"></span>
                    )}
                  </button>
                </div>
                {this.state.errorMessage && (
                  <div className="c-alert c-alert--danger">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>
                    {this.state.errorMessage}
                  </div>
                )}
              </div>
              <div className="modal-footer modal-footer--border">
                <a
                  className="btn uhm-petrol"
                  href={window.createeditad.mypageurl}
                >
                  {this.state.publishSucceed
                    ? "Till översiktssida"
                    : "Stäng och skicka påminnelse till mig automatiskt"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onBeforeUnload(event) {
    if (formChanged) {
      return (event.returnValue = "Write something clever here..");
    }
  }

  /** ------------ Change handlers ----------------*/

  handleHeaderChange(inputHeader) {
    this.setState({
      header: inputHeader.target.value,
    });
    if (this.state.header && !this.state.headerFocus) {
      this.setState({
        headerValid: "-valid",
      });
    } else {
      this.setState({
        headerValid: "",
      });
    }
  }

  handleSystemStartDateChange(inputDate) {
    this.setState({
      systemStartDate: inputDate,
      systemStartDateRaw: inputDate,
      systemStartDateValid: "",
    });
  }

  handleSystemStartDateRawChange(inputDateRaw) {
    this.setState({
      systemStartDateRaw: inputDateRaw.target.value,
    });
  }

  handleSystemEndDateChange(inputDate) {
    this.setState({
      systemEndDate: inputDate,
      systemEndDateRaw: inputDate,
      systemEndDateValid: "",
    });
  }

  handleSystemEndDateRawChange(inputDateRaw) {
    this.setState({
      systemEndDateRaw: inputDateRaw.target.value,
    });
  }

  handleRequirementsCheckboxChange(event, index) {
    let value = parseInt(event.target.value);
    let array = [];
    let newRequirements = [];

    this.state.requirements.forEach((item) => {
      newRequirements.push({
        ...item,
        selected: item.value === value ? event.target.checked : item.selected,
      });
    });
    if (event.target.checked) {
      this.setState({
        selectedRequirements: [...this.state.selectedRequirements, value],
        requirements: newRequirements,
      });
    } else {
      array = this.state.selectedRequirements.filter((item) => item !== value);
      this.setState({
        selectedRequirements: array,
        requirements: newRequirements,
      });
    }
  }

  handleSystemEndDateOptionChange(event) {
    this.setState({
      systemEndDateOption: parseInt(event.target.value),
    });
  }

  handleDescriptionChange(inputDescription) {
    this.setState({
      description: inputDescription.target.value,
    });
  }

  handleBusinessAreaOptionChange(value) {
    let index = this.state.selectedBusinessAreas.indexOf(value);
    let newArray = [];
    if (index > -1) {
      newArray = this.state.selectedBusinessAreas.filter(
        (item) => item !== value
      );
      this.setState({
        selectedBusinessAreas: [...newArray],
        invalidFields: {
          businessAreas:
            newArray.length === 0 && !this.state.noBusinessAreaMatchedSelected
              ? true
              : false,
        },
      });
    } else {
      newArray.push(value);
      this.setState({
        selectedBusinessAreas: [
          ...this.state.selectedBusinessAreas,
          ...newArray,
        ],
        invalidFields: {},
      });
    }
  }

  handleNoBusinessAreaMatched() {
    this.setState({
      noBusinessAreaMatchedSelected: !this.state.noBusinessAreaMatchedSelected,
      invalidFields: {
        businessAreas:
          this.state.selectedBusinessAreas.length === 0 &&
          this.state.noBusinessAreaMatchedSelected
            ? true
            : false,
      },
    });
  }

  handleInqueryURLChange(inputURL) {
    this.setState({
      inqueryUrl: inputURL.target.value,
    });
    if (this.state.inqueryUrl && !this.state.inqueryUrlFocus) {
      this.setState({
        inqueryUrlValid: "-valid",
      });
    } else {
      this.setState({
        inqueryUrlValid: "",
      });
    }
  }

  handleInqueryUpdatedChange(inputDate) {
    this.setState({
      inqueryUpdated: inputDate,
      inqueryUpdatedRaw: inputDate,
      inqueryUpdatedValid: "",
    });
  }

  handleInqueryUpdatedRawChange(inputDateRaw) {
    this.setState({
      inqueryUpdatedRaw: inputDateRaw.target.value,
    });
  }

  handleMailChange(inputMail) {
    if (inputMail.target.id == "additionalEmail") {
      this.setState({
        additionalMail: inputMail.target.value,
      });
      if (this.state.additionalMail && !this.state.additionalMailFocus) {
        this.setState({
          additionalMailValid: "-valid",
        });
      } else {
        this.setState({
          additionalMailValid: "",
        });
      }
    } else {
      this.setState({
        mail: inputMail.target.value,
      });
      if (this.state.mail && !this.state.mailFocus) {
        this.setState({
          mailValid: "-valid",
        });
      } else {
        this.setState({
          mailValid: "",
        });
      }
    }
  }

  handleNameChange(inputName) {
    if (inputName.target.id == "additionalName") {
      this.setState({
        additionalName: inputName.target.value,
      });
      if (this.state.additionalName && !this.state.additionalNameFocus) {
        this.setState({
          additionalNameValid: "-valid",
        });
      } else {
        this.setState({
          additionalNameValid: "",
        });
      }
    } else {
      this.setState({
        name: inputName.target.value,
      });
      if (this.state.name && !this.state.nameFocus) {
        this.setState({
          nameValid: "-valid",
        });
      } else {
        this.setState({
          nameValid: "",
        });
      }
    }
  }

  handleRegistrationNrChange(inputregistrationNr) {
    this.setState({
      registrationNr: inputregistrationNr.target.value,
    });
    if (this.state.registrationNr && !this.state.registrationNrFocus) {
      this.setState({
        registrationNrValid: "-valid",
      });
    } else {
      this.setState({
        registrationNrValid: "",
      });
    }
  }

  handlePhoneNumberChange(inputPhoneNumber) {
    if (inputPhoneNumber.target.id == "additionalPhone") {
      this.setState({
        additionalPhoneNumber: inputPhoneNumber.target.value,
      });
      if (
        this.state.additionalPhoneNumber &&
        !this.state.additionalPhoneNumberFocus
      ) {
        this.setState({
          additionalPhoneNumberValid: "-valid",
        });
      } else {
        this.setState({
          additionalPhoneNumberValid: "",
        });
      }
    } else {
      this.setState({
        phoneNumber: inputPhoneNumber.target.value,
      });
      if (this.state.phoneNumber && !this.state.phoneNumberFocus) {
        this.setState({
          phoneNumberValid: "-valid",
        });
      } else {
        this.setState({
          phoneNumberValid: "",
        });
      }
    }
  }

  handleToggleAdditionalContact(event) {
    event.preventDefault();
    this.setState({
      additionalName: "",
      additionalMail: "",
      additionalPhoneNumber: "",
      additionalNameFocus: "",
      additionalMailFocus: "",
      additionalPhoneNumberFocus: "",
      additionalNameValid: "",
      additionalMailValid: "",
      additionalPhoneNumberValid: "",
      additionalContactActive: !this.state.additionalContactActive,
    });
  }

  /** ------------ Focus handlers ----------------*/

  handleDescriptionFocus() {
    this.handleUnfocus();
    this.setState({
      descriptionFocus: "-focus",
    });
  }

  handleHeaderFocus() {
    this.handleUnfocus();
    this.setState({
      headerFocus: "-focus",
    });
  }

  handleSystemStartDateFocus() {
    this.handleUnfocus();
    this.setState({
      systemStartDateFocus: "focus",
    });
  }

  handleSystemEndDateFocus() {
    this.handleUnfocus();
    this.setState({
      systemEndDateFocus: "focus",
    });
  }

  handleInqueryUpdatedFocus() {
    this.handleUnfocus();
    this.setState({
      inqueryUpdatedFocus: "focus",
    });
  }

  handleMailFocus(mail) {
    this.handleUnfocus();
    if (mail.target.id == "additional-mail") {
      this.setState({
        additionalMailFocus: "-focus",
      });
    } else {
      this.setState({
        mailFocus: "-focus",
      });
    }
  }

  handleNameFocus(name) {
    this.handleUnfocus();
    if (name.target.id == "additional-name") {
      this.setState({
        additionalNameFocus: "-focus",
      });
    } else {
      this.setState({
        nameFocus: "-focus",
      });
    }
  }

  handleRegistrationNrFocus() {
    this.handleUnfocus();
    this.setState({
      registrationNrFocus: "-focus",
    });
  }

  handleInqueryURLFocus() {
    this.handleUnfocus();
    this.setState({
      inqueryUrlFocus: "-focus",
    });
  }

  handlePhoneNumberFocus(phoneNumber) {
    this.handleUnfocus();
    if (phoneNumber.target.id == "additional-phoneNumber") {
      this.setState({
        additionalPhoneNumberFocus: "-focus",
      });
    } else {
      this.setState({
        phoneNumberFocus: "-focus",
      });
    }
  }

  handleUnfocus() {
    let dateFormat = "YYYY-MM-DD";
    this.setState({
      headerFocus: "",
      systemStartDateFocus: "",
      systemEndDateFocus: "",
      descriptionFocus: "",
      inqueryUrlFocus: "",
      inqueryUpdatedFocus: "",
      nameFocus: "",
      phoneNumberFocus: "",
      mailFocus: "",
      additionalNameFocus: "",
      additionalPhoneNumberFocus: "",
      additionalMailFocus: "",
      registrationNrFocus: "",
    });
  }

  onClickPublish(e) {
    e.preventDefault();
    const validationResult = this.validateAllFields();

    if (Object.keys(validationResult).length === 0) {
      this.setState({
        publishLoading: true,
      });
      this.publishAd();
    } else {
      this.setState({
        errorMessage: window.createeditad.errorValidation,
        publishLoading: false,
      });
      this.scrollToTop();
    }
  }

  validateAllFields() {
    var validationResult = this.formCreateEditAd.validateAll();
    if (
      !this.state.noBusinessAreaMatchedSelected &&
      this.state.selectedBusinessAreas.length === 0
    ) {
      this.setState({
        invalidFields: { businessAreas: true },
      });
      validationResult = { ...validationResult, businessAreas: ["required"] };
    } else {
      this.setState({
        invalidFields: {},
      });
      delete validationResult.businessAreas;
    }
    return validationResult;
  }

  publishAd() {
    var data = this.getStateData();

    fetch("/api/sv/announcement/save?action=PUBLISH", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.isValid) {
          if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({
              event: "publishedAd",
              advertiser: this.state.regionName,
            });
          }
          formChanged = false;
          this.setState({
            publishLoading: false,
            errorMessage: "",
            selectedReviewMonth: result.selectedReviewMonth,
            id: result.adId,
          });
          const modalButton = document.createElement("button");
          modalButton.innerText = "Open review reminder modal";
          modalButton.classList.add("sr-only");
          modalButton.setAttribute("data-toggle", "modal");
          modalButton.setAttribute("data-target", "#reviewReminder");
          modalButton.addEventListener("click", function (e) {
            e.preventDefault();
          });
          document.body.appendChild(modalButton);
          modalButton.click();
          document.body.removeChild(modalButton);
        } else {
          this.setState({
            publishLoading: false,
            errorMessage: result.validationErrors[0],
          });
          this.scrollToTop();
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: window.createeditad.errorPublish,
          publishLoading: false,
        });
        this.scrollToTop();
      });
  }

  onClickSaveDraft(e) {
    e.preventDefault();
    const validationResult = this.validateAllFields();
    this.setState({
      saveDraftLoading: true,
    });
    if (Object.keys(validationResult).length === 0) {
      this.saveDraft();
    } else {
      this.setState({
        errorMessage: window.createeditad.errorValidation,
        saveDraftLoading: false,
      });
      this.scrollToTop();
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  saveDraft() {
    var data = this.getStateData(this.state);

    fetch("/api/sv/announcement/save?action=SAVEDRAFT", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({
            errorMessage: window.createeditad.errorSaving,
            saveDraftLoading: false,
          });
          this.scrollToTop();
        }
      })
      .then((json) => {
        if (json.isValid) {
          window.onbeforeunload = null;
          window.location.href = window.createeditad.mypageurl;
        } else {
          this.setState({
            errorMessage: json.validationErrors[0],
            saveDraftLoading: false,
          });
          this.scrollToTop();
        }
      });
  }

  onClickUnpublish(e) {
    var data = { Id: this.state.id };

    fetch("/api/sv/announcement/save?action=UNPUBLISH", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      if (response.ok) {
        formChanged = false;
        window.location.href = window.createeditad.mypageurl;
      } else {
        this.setState({
          errorMessage: window.createeditad.errorUnpublish,
        });
      }
    });
  }

  onClickReviewReminder() {
    fetch(
      `/api/sv/announcement/${this.state.id}/configure-review?month=${this.state.selectedReviewMonth}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.isValid) {
          this.setState({
            publishSucceed: true,
          });
        } else {
          this.setState({
            errorMessage:
              result.validationErrors[0] ||
              "Ett okänt fel uppstod. Försök igen om en liten stund.",
          });
        }
      });
  }

  getSelectedCategories() {
    var selectedBusinessAreas = [];

    this.state.businessAreas.forEach(function (item) {
      if (this.state["businessArea" + item.businessArea.id]) {
        selectedBusinessAreas.push(item.businessArea.title);
      }

      item.children.forEach(function (childItem) {
        if (this.state["businessArea" + childItem.id]) {
          selectedBusinessAreas.push(
            item.businessArea.title + " - " + childItem.title
          );
        }
      }, this);
    }, this);

    return selectedBusinessAreas;
  }

  getStateData() {
    return {
      Id: this.state.id,
      Title: this.state.header,
      SystemStartDate: this.state.systemStartDate,
      SystemEndDate:
        this.state.systemEndDateOption === 1 ? this.state.systemEndDate : null,
      Description: this.state.description,
      InqueryUrl: this.state.inqueryUrl,
      InqueryUpdated: this.state.inqueryUpdated,
      ContactPerson: this.state.name,
      PhoneNumber: this.state.phoneNumber,
      EmailAddress: this.state.mail,
      ContactPerson2: this.state.additionalName,
      PhoneNumber2: this.state.additionalPhoneNumber,
      EmailAddress2: this.state.additionalMail,
      SelectedBusinessAreas: this.state.noBusinessAreaMatchedSelected
        ? [-1]
        : this.state.selectedBusinessAreas,
      RegistryNo: this.state.registrationNr,
      SelectedRequirements: this.state.selectedRequirements,
    };
  }
}

function formatZip(zip) {
  if (zip) {
    zip = zip.replace(/ /g, "");
    if (zip.length >= 5) {
      zip = zip.slice(0, 3) + " " + zip.slice(3);
    }
  }
  return zip;
}

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
}

if (document.getElementById("create-announcement")) {
  ReactDOM.render(<CreateAd />, document.getElementById("create-announcement"));
}
