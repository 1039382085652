import jQuery from "jquery";

let component_preview_modal = {
  init() {
    $(document).ready(function () {
      const urlParams = new URLSearchParams(window.location.search);
      const showAdId = urlParams.get("granska");
      const showAdBtn = $(`#preview-btn-${showAdId}`);
      showAdBtn.trigger("click");

      if (showAdBtn && window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({
          event: "reviewAdvert",
        });
      }
      let reviewedButtons = $('[data-submit="reviewed"]');
      reviewedButtons.each((index, reviewedButton) => {
        $(reviewedButton).on("click", function () {
          if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({
              event: "reviewAdvert",
            });
          }

          let adId = $(reviewedButton).attr("id");
          fetch(`/api/sv/announcement/${adId}/reviewed`, {
            method: "POST",
            credentials: "include",
          }).then((response) => {
            if (response.ok) {
              var pageURL = $(location).attr("href");
              window.location.replace(pageURL.split("?")[0]);
            } else {
              alert("Ett fel uppstod. Vänligen försök senare.");
            }
          });
        });
      });
    });
  },
};

export default component_preview_modal;
