import $ from 'jquery';

let UsabilitySkipContent = {
  elemSelector: '.js-skip-content',
  namespaceKeydown: 'keydown.skip-content',
  activatedTab: false,
  tabCounter: 0,
  $elem: null,

  init() {
    this.$elem = $(this.elemSelector);
    if (this.$elem.length === 0) return;

    $('body').on(this.namespaceKeydown, (event) => this._keydownHandler(event));
    $('body').on('click keypress', (event) => this._activityHandler(event));
    this.$elem.find('a').on('click', (event) => this._linkHandler(event));
  },

  _keydownHandler(event) {
    let TAB = 9;

    if(this.activatedTab) {
      this._closePanel();
    }

    if (this.activatedTab === false && event.which === TAB) {
      this.$elem.show();
      let links = this.$elem.find('a');

      if(this.tabCounter > 0) {
        $(links[this.tabCounter - 1]).hide();
      }

      let link = links[this.tabCounter++];
      $(link).show().focus();
      
      if(links.length <= this.tabCounter){
        this.activatedTab = true;
      }

      event.preventDefault();
    }
  },

  _activityHandler(event) {
    $('body').off(this.namespaceKeydown);
    if($('*:focus').length === 0) {
      this._closePanel();
    }
  },

  _linkHandler(event) {
    this._closePanel();
  },

  _closePanel() {
    this.$elem.hide();
    $('body').off(this.namespaceKeydown);
  }

};

export default UsabilitySkipContent;