import jQuery from 'jquery';

let component_spinner = {

    init() {
        
        $('.btn--export').on('click', function () {
            $('.c-spinner').addClass('c-spinner--visible');
        });
    }

};

export default component_spinner;