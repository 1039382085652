import React from "react";
import Select from "react-select";

export class RegionSelect extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    if (this.props.regionOption === 0) {
      return (
        <div>
          <label htmlFor="region-select" className="form__label">
            Kommun <abbr title="Obligatoriskt">*</abbr>
          </label>
          <select
            id="region-select"
            placeholder="Välj&hellip;"
            value={this.props.activeMunicipalityId}
            onChange={this.props.handleRegionChange}
            className="form__select"
          >
            {this.props.dropDownMunicipality &&
              this.props.dropDownMunicipality.map(({ value, label }) => {
                return (
                  <option value={value} key={value}>
                    {label}
                  </option>
                );
              })}
          </select>
          {/* <Select
            id="region-select"
            placeholder="Välj&hellip;"
            multi={false}
            value={this.props.activeMunicipalityId}
            options={this.props.dropDownMunicipality}
            onChange={this.props.handleRegionChange}
            clearable={true}
          /> */}
        </div>
      );
    }
    if (this.props.regionOption === 1) {
      return (
        <div>
          <label htmlFor="region-select" className="form__label">
            Landsting <abbr title="Obligatoriskt">*</abbr>
          </label>
          <select
            id="region-select"
            placeholder="Välj&hellip;"
            value={this.props.activeCountryCouncilId}
            onChange={this.props.handleRegionChange}
            className="form__select"
          >
            {this.props.dropDownCountryCouncil &&
              this.props.dropDownCountryCouncil.map(({ value, label }) => {
                return (
                  <option value={value} key={value}>
                    {label}
                  </option>
                );
              })}
          </select>
          {/* <Select
            id="region-select"
            placeholder="Välj&hellip;"
            multi={false}
            value={this.props.activeCountryCouncilId}
            options={this.props.dropDownCountryCouncil}
            onChange={this.props.handleRegionChange}
            clearable={true}
          /> */}
        </div>
      );
    }
    if (this.props.regionOption === 2) {
      return (
        <div>
          <label htmlFor="region-select" className="form__label">
            Statlig myndighet <abbr title="Obligatoriskt">*</abbr>
          </label>
          <select
            id="region-select"
            placeholder="Välj&hellip;"
            value={this.props.activeGovernmentOrgId}
            onChange={this.props.handleRegionChange}
            className="form__select"
          >
            {this.props.dropDownGovernmentOrg &&
              this.props.dropDownGovernmentOrg.map(({ value, label }) => {
                return (
                  <option value={value} key={value}>
                    {label}
                  </option>
                );
              })}
          </select>
          {/* <Select
            id="region-select"
            placeholder="Välj&hellip;"
            multi={false}
            value={this.props.activeGovernmentOrgId}
            options={this.props.dropDownGovernmentOrg}
            onChange={this.props.handleRegionChange}
            clearable={true}
          /> */}
        </div>
      );
    }
    return <div></div>;
  }
}
