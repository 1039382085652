import React from "react";
import ReactDOM from "react-dom";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import { RegionSelect } from "./c-regionSelect.js";
import Validation from "react-validation";
import { isEmail, isAlpha } from "../../validator";

require("es6-promise").polyfill();

export default class RegisterAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMail: true,
      mail: "",
      mailFocus: "",
      mailValid: "",
      password: "",
      passwordFocus: "",
      passwordValid: "",
      repeatPassword: "",
      repeatPasswordFocus: "",
      repeatPasswordValid: "",
      firstName: "",
      lastName: "",
      firstNameFocus: "",
      lastNameFocus: "",
      firstNameValid: "",
      lastNameValid: "",
      phoneNumber: "",
      phoneNumberFocus: "",
      phoneNumberValid: "",
      role: "",
      regionOption: 0,
      errorMessage: "",
      errorLoginMessage: "",
      loading: false,
      infoMessage: "",
      dropDownMunicipality: [],
      activeMunicipalityId: "",
      dropDownCountryCouncil: [],
      activeCountryCouncilId: "",
      dropDownGovernmentOrg: [],
      activeGovernmentOrgId: "",
    };

    this.handleMailChange = this.handleMailChange.bind(this);
    // Password input events
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this);
    this.handleRepeatPasswordChange =
      this.handleRepeatPasswordChange.bind(this);
    this.handleRepeatPasswordFocus = this.handleRepeatPasswordFocus.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleMailFocus = this.handleMailFocus.bind(this);
    this.handleFirstNameFocus = this.handleFirstNameFocus.bind(this);
    this.handleLastNameFocus = this.handleLastNameFocus.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handlePhoneNumberFocus = this.handlePhoneNumberFocus.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleRegisterUser = this.handleRegisterUser.bind(this);
  }

  componentWillMount() {
    this.getMunicipalities();
    this.getCountyConcils();
    this.getGovernmentOrgs();

    this.setState({
      role: this.getSelectedRole(),
    });
  }

  render() {
    return (
      <div>
        <section className="page-wrapper">
          <div className="page-wrapper__layout">
            <div className="row">
              <div className="col-md-7">
                <Validation.components.Form
                  ref={(c) => {
                    this.form = c;
                  }}
                  onSubmit={(event) => this.handleRegisterUser(event)}
                >
                  <div className="margin-bottom-30">
                    <h2>Registrera ny användare</h2>
                    <p>Fyll i din e-postadress kopplad till din arbetsplats på kommun, region eller myndighet.</p>
                  </div>
                  <div className="row" id="error-alert">
                    {this.state.errorMessage && (
                      <div className="col-sm-7">
                        <div className="c-alert c-alert--danger">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {this.state.errorMessage}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handleMailChange}
                        onFocus={this.handleMailFocus}
                        onBlur={this.handleUnfocus}
                        label="E-post"
                        value={this.state.mail}
                        type="input"
                        name="email"
                        className="form__input"
                        id="mail"
                        focus={this.state.mailFocus}
                        valid={this.state.mailValid}
                        validations={["required", "email"]}
                        abbr="true"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handlePasswordChange}
                        onFocus={this.handlePasswordFocus}
                        onBlur={this.handleUnfocus}
                        label="Lösenord"
                        value={this.state.password}
                        type="password"
                        name="password"
                        className="form__input"
                        id="password"
                        focus={this.state.passwordFocus}
                        valid={this.state.passwordValid}
                        validations={["required", "passwordStrength"]}
                        abbr="true"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="c-alert c-alert--info">
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>
                        Ditt lösenord ska innehålla minst 8 tecken varav en stor
                        bokstav.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handleRepeatPasswordChange}
                        onFocus={this.handleRepeatPasswordFocus}
                        onBlur={this.handleUnfocus}
                        label="Upprepa lösenord"
                        value={this.state.repeatPassword}
                        type="password"
                        name="passwordConfirm"
                        className="form__input"
                        id="passwordConfirm"
                        focus={this.state.repeatPasswordFocus}
                        valid={this.state.repeatPasswordValid}
                        validations={["required", "password"]}
                        abbr="true"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handleFirstNameChange}
                        onFocus={this.handleFirstNameFocus}
                        onBlur={this.handleUnfocus}
                        label="Förnamn"
                        value={this.state.firstName}
                        type="input"
                        name="firstName"
                        className="form__input"
                        id="firstName"
                        focus={this.state.firstNameFocus}
                        valid={this.state.firstNameValid}
                        validations={["required", "alpha"]}
                        abbr="true"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handleLastNameChange}
                        onFocus={this.handleLastNameFocus}
                        onBlur={this.handleUnfocus}
                        label="Efternamn"
                        value={this.state.lastName}
                        type="input"
                        name="lastName"
                        className="form__input"
                        id="lastName"
                        focus={this.state.lastNameFocus}
                        valid={this.state.lastNameValid}
                        validations={["required", "alpha"]}
                        abbr="true"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="form__element-wrapper">
                        <label htmlFor="role-select" className="form__label">
                          Roll
                        </label>
                        <select
                          id="role-select"
                          placeholder="Välj&hellip;"
                          value={this.state.role}
                          onChange={this.handleRoleChange}
                          defaultValue={this.state.role}
                          className="form__select"
                        >
                          {window.registeraccount.roles &&
                            window.registeraccount.roles.map((role) => {
                              return (
                                <option value={role.value} key={role.value}>
                                  {role.text}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handlePhoneNumberChange}
                        onFocus={this.handlePhoneNumberFocus}
                        onBlur={this.handleUnfocus}
                        label="Telefonnummer (dagtid)"
                        value={this.state.phoneNumber}
                        type="input"
                        name="phoneNumber"
                        id="phoneNumber"
                        className="form__input"
                        focus={this.state.phoneNumberFocus}
                        valid={this.state.phoneNumberValid}
                        validations={["phone"]}
                      />
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="collapse-additional-info"
                    aria-expanded="false"
                  >
                    <p className="register-account-page__info-text">
                      Din e-postadress finns inte registrerad i vårt system och
                      vi behöver därför veta vilken kommun och organisation du
                      tillhör.
                    </p>
                    <div className="row">
                      <div className="col-sm-7">
                        <fieldset className="form__fieldset">
                          <legend className="form__legend">
                            Välj organisation{" "}
                            <abbr title="Obligatoriskt">*</abbr>
                          </legend>
                          <div className="form__radio-wrapper">
                            <label htmlFor="radio-Municipality">
                              <input
                                id="radio-Municipality"
                                className="radio-input"
                                type="radio"
                                value="Kommun"
                                checked={this.state.regionOption === 0}
                                onChange={this.handleOptionChange}
                                name="municipalityId"
                              />
                              <span>Kommun</span>
                            </label>
                          </div>
                          <div className="form__radio-wrapper">
                            <label htmlFor="radio-CountryCouncil">
                              <input
                                id="radio-CountryCouncil"
                                className="radio-input"
                                type="radio"
                                value="Landsting/Region"
                                checked={this.state.regionOption === 1}
                                onChange={this.handleOptionChange}
                                name="countryCouncilId"
                              />
                              <span>Landsting/Region</span>
                            </label>
                          </div>
                          <div className="form__radio-wrapper">
                            <label htmlFor="radio-arbets">
                              <input
                                id="radio-arbets"
                                className="radio-input"
                                type="radio"
                                value="Statlig myndighet"
                                checked={this.state.regionOption === 2}
                                onChange={this.handleOptionChange}
                                name="region"
                              />
                              <span>Statlig myndighet</span>
                            </label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="form__element-wrapper">
                          <RegionSelect
                            regionOption={this.state.regionOption}
                            activeMunicipalityId={
                              this.state.activeMunicipalityId
                            }
                            activeCountryCouncilId={
                              this.state.activeCountryCouncilId
                            }
                            activeGovernmentOrgId={
                              this.state.activeGovernmentOrgId
                            }
                            dropDownMunicipality={
                              this.state.dropDownMunicipality
                            }
                            dropDownCountryCouncil={
                              this.state.dropDownCountryCouncil
                            }
                            dropDownGovernmentOrg={
                              this.state.dropDownGovernmentOrg
                            }
                            handleRegionChange={this.handleRegionChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="register-account-page__button">
                    <a
                      href={window.registeraccount.apiurl}
                      className="btn-secondary"
                      tabIndex="0"
                    >
                      Tillbaka
                    </a>
                    <input
                      type="submit"
                      value="Registrera användare"
                      className="btn uhm-petrol"
                      tabIndex="0"
                      disabled={this.state.loading}
                    />
                  </div>
                </Validation.components.Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  //Handlers --------------------------------------------------------------------------------------------------
  getSelectedRole() {
    let selectedRole = window.registeraccount.roles.find(
      (role) => role.selected === true
    );
    if (selectedRole) {
      return selectedRole.value;
    } else {
      return window.registeraccount.roles[0].value;
    }
  }

  handleRegionChange(val) {
    console.log(val.target.value);
    if (this.state.regionOption === 0) {
      if (val) {
        this.setState({
          activeMunicipalityId: val.target.value,
        });
      } else {
        this.setState({
          activeMunicipalityId: "",
        });
      }
    }
    if (this.state.regionOption === 1) {
      if (val) {
        this.setState({
          activeCountryCouncilId: val.target.value,
        });
      } else {
        this.setState({
          activeCountryCouncilId: "",
        });
      }
    }
    if (this.state.regionOption === 2) {
      if (val) {
        this.setState({
          activeGovernmentOrgId: val.target.value,
        });
      } else {
        this.setState({
          activeGovernmentOrgId: "",
        });
      }
    }
  }

  handleOptionChange(changeEvent) {
    if (changeEvent.target.value == "Landsting/Region") {
      this.setState({
        regionOption: 1,
        activeCountryCouncilId: this.state.dropDownCountryCouncil[0].value,
        activeMunicipalityId: "",
        activeGovernmentOrgId: "",
      });
    } else if (changeEvent.target.value == "Statlig myndighet") {
      this.setState({
        regionOption: 2,
        activeGovernmentOrgId: this.state.dropDownGovernmentOrg[0].value,
        activeCountryCouncilId: "",
        activeMunicipalityId: "",
      });
    } else {
      this.setState({
        regionOption: 0,
        activeMunicipalityId: this.state.dropDownMunicipality[0].value,
        activeGovernmentOrgId: "",
        activeCountryCouncilId: "",
      });
    }
  }

  handleMailChange(inputMail) {
    this.setState({
      mail: inputMail.target.value,
    });
    if (this.state.mail && !this.state.mailFocus) {
      this.setState({
        mailValid: "-valid",
      });
    } else {
      this.setState({
        mailValid: "",
      });
    }
  }

  handlePasswordChange(inputPassword) {
    this.setState({
      password: inputPassword.target.value,
    });
    if (this.state.password && !this.state.passwordFocus) {
      this.setState({
        passwordValid: "-valid",
      });
    } else {
      this.setState({
        passwordValid: "",
      });
    }
  }

  handleRepeatPasswordChange(inputRepeatPassword) {
    this.setState({
      repeatPassword: inputRepeatPassword.target.value,
    });
    if (this.state.repeatPassword && !this.state.repeatPasswordFocus) {
      this.setState({
        repeatPasswordValid: "-valid",
      });
    } else {
      this.setState({
        repeatPasswordValid: "",
      });
    }
  }

  handleFirstNameChange(inputFirstName) {
    this.setState({
      firstName: inputFirstName.target.value,
    });
    if (this.state.firstName && !this.state.firstNameFocus) {
      this.setState({
        firstNameValid: "-valid",
      });
    } else {
      this.setState({
        firstNameValid: "",
      });
    }
  }

  handleLastNameChange(inputLastName) {
    this.setState({
      lastName: inputLastName.target.value,
    });
    if (this.state.lastName && !this.state.lastNameFocus) {
      this.setState({
        lastNameValid: "-valid",
      });
    } else {
      this.setState({
        lastNameValid: "",
      });
    }
  }

  handleRoleChange(selectedOption) {
    this.setState({
      role: selectedOption.target.value,
    });
  }

  handlePhoneNumberChange(inputPhoneNumber) {
    this.setState({
      phoneNumber: inputPhoneNumber.target.value,
    });
    if (this.state.phoneNumber && !this.state.phoneNumberFocus) {
      this.setState({
        phoneNumberValid: "-valid",
      });
    } else {
      this.setState({
        phoneNumberValid: "",
      });
    }
  }

  handleRegisterUser(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    const validationResult = this.form.validateAll();

    if (Object.keys(validationResult).length === 0) {
      let newUser = {
        mail: this.state.mail,
        password: this.state.password,
        repeatPassword: this.state.repeatPassword,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        advertizerRoleId: this.state.role,
        phoneNumber: this.state.phoneNumber,
        region: this.state.regionOption,
        countryCouncilId: this.state.activeCountryCouncilId,
        municipalityId: this.state.activeMunicipalityId,
        governmentOrgId: this.state.activeGovernmentOrgId,
        __RequestVerificationToken: window.registeraccount.antiforgery,
      };
      this.saveData(newUser);
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        errorMessage: window.registeraccount.errorValidation,
        loading: false,
      });
      this.scrollToTop();
    }

    // if (
    //   isEmail(this.state.mail) &&
    //   isAlpha(this.state.firstName) &&
    //   isAlpha(this.state.lastName) &&
    //   this.state.regionOption != -1
    // ) {
    //   let newUser = {
    //     mail: this.state.mail,
    //     password: this.state.password,
    //     repeatPassword: this.state.repeatPassword,
    //     firstName: this.state.firstName,
    //     lastName: this.state.lastName,
    //     advertizerRoleId: this.state.role,
    //     phoneNumber: this.state.phoneNumber,
    //     region: this.state.regionOption,
    //     countryCouncilId: this.state.activeCountryCouncilId,
    //     municipalityId: this.state.activeMunicipalityId,
    //     governmentOrgId: this.state.activeGovernmentOrgId,
    //     __RequestVerificationToken: window.registeraccount.antiforgery,
    //   };
    //   this.saveData(newUser);
    //   this.setState({
    //     loading: false,
    //   });
    // } else {
    //   this.form.validateAll();
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleMailFocus() {
    this.setState({
      mailFocus: "-focus",
      passwordFocus: "",
      repeatPasswordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handlePasswordFocus() {
    this.setState({
      passwordFocus: "-focus",
      repeatPasswordFocus: "",
      mailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleRepeatPasswordFocus() {
    this.setState({
      repeatPasswordFocus: "-focus",
      passwordFocus: "",
      mailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleFirstNameFocus() {
    this.setState({
      firstNameFocus: "-focus",
      mailFocus: "",
      passwordFocus: "",
      repeatPasswordFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleLastNameFocus() {
    this.setState({
      lastNameFocus: "-focus",
      mailFocus: "",
      passwordFocus: "",
      repeatPasswordFocus: "",
      firstNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handlePhoneNumberFocus() {
    this.setState({
      phoneNumberFocus: "-focus",
      mailFocus: "",
      passwordFocus: "",
      repeatPasswordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
    });
  }

  handleUnfocus() {
    this.setState({
      mailFocus: "",
      passwordFocus: "",
      firstNameFocus: "",
      repeatPasswordFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });

    if (this.state.mail && isEmail(this.state.mail) && this.state.mail) {
      this.getInformationByMail(this.state.mail);
    }
  }

  //db --------------------------------------------------------------------------------------------------

  isUser(user, password) {
    fetch(window.registeraccount.apiurl + "Validate", {
      body: JSON.stringify({
        email: user,
        password: password,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsError || result.status >= 300) {
          this.setState({
            errorMessage: result.Error
              ? result.Error
              : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
          });
        } else {
          window.location.href = result.RedirectTo;
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
        });
      });
  }

  resetPassword(user) {
    fetch(window.registeraccount.apiurl + "ResetPassword", {
      body: JSON.stringify({
        email: user,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
    })
      .then((response) => {
        if (response.status >= 300) {
          // console.log('ErrorResponse', response);
        }
        return response.json();
      })
      .then((json) => {
        if (json.IsError) {
          // Handle the error here
          this.handleLoginError(json.Error);
          return json;
        } else {
          this.handleLoginError(json.Info);
        }
      });
  }

  getMunicipalities() {
    fetch(window.registeraccount.apiurl + "Municipalities/", {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status >= 300) {
          console.log("ErrorResponse", response);
        }
        let dropdown = data.map((option) => {
          return { value: option.Value, label: option.Text };
        });
        this.setState({
          dropDownMunicipality: dropdown,
        });
        return data;
      })
      .catch(() => {
        console.log("error");
      });
  }

  getCountyConcils() {
    fetch(window.registeraccount.apiurl + "CountyCouncils/", {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status >= 300) {
          console.log("ErrorResponse", data);
        }
        let dropdown = data.map((option) => {
          return { value: option.Value, label: option.Text };
        });
        this.setState({
          dropDownCountryCouncil: dropdown,
        });
        return data;
      })
      .catch(() => {
        console.log("error");
      });
  }

  getGovernmentOrgs() {
    fetch(window.registeraccount.apiurl + "GovernmentOrgs/", {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status >= 300) {
          console.log("ErrorResponse", data);
        }
        let dropdown = data.map((option) => {
          return { value: option.Value, label: option.Text };
        });
        this.setState({
          dropDownGovernmentOrg: dropdown,
        });
        return data;
      })
      .catch(() => {
        console.log("error");
      });
  }

  getInformationByMail(mail) {
    fetch(window.registeraccount.apiurl + "WhiteList/?email=" + mail, {
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status >= 300) {
          console.log("ErrorResponse", data);
        }
        if (data) {
          if (data.regionOption == "Kommun") {
            this.setState({
              regionOption: 0,
              activeMunicipalityId: data.regionOptionId,
              activeCountryCouncilId: "",
              activeGovernmentOrgId: "",
            });
          } else if (data.regionOption == "Landsting") {
            this.setState({
              regionOption: 1,
              activeCountryCouncilId: data.regionOptionId,
              activeMunicipalityId: "",
              activeGovernmentOrgId: "",
            });
          } else {
            this.setState({
              regionOption: 2,
              activeGovernmentOrgId: data.regionOptionId,
              activeMunicipalityId: "",
              activeCountryCouncilId: "",
            });
          }
          $("#collapse-additional-info").collapse("hide");
        } else {
          this.setState({
            regionOption: 0,
            activeMunicipalityId: this.state.dropDownMunicipality[0].value,
          });
          $("#collapse-additional-info").collapse("show");
        }
        return data;
      })
      .catch(() => {
        console.log("error");
      });
  }

  //post
  saveData(form) {
    //Check form no null or undefined value
    if (!!form) {
      fetch(window.registeraccount.apiurl + "Register", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.IsError || result.status >= 300) {
            this.setState({
              errorMessage: result.Error
                ? result.Error
                : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            window.location.href = result.RedirectTo;
          }
        })
        .catch((error) => {
          this.setState({
            errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  }
}

const registerAccountDiv = document.getElementById("registeraccount");
if (registerAccountDiv) {
  ReactDOM.render(<RegisterAccount />, registerAccountDiv);
}
