import React from "react";
import Validation from "react-validation";
import UHMValidation from "./validation.js";

class InputReactWithBootstrap extends React.Component {
  render() {
    if (this.props.type == "textarea") {
      return (
        <div className={this.props.containerClassName}>
          <div className="form__element-wrapper">
            <label className="form__label" htmlFor={this.props.id}>
              <span>{this.props.label}</span>{" "}
              {this.props.abbr && <abbr title="Obligatoriskt">*</abbr>}
            </label>
            <Validation.components.Textarea
              className={this.props.className}
              id={this.props.id}
              type={this.props.type}
              name={this.props.name}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              onFocus={this.props.onFocus}
              value={this.props.value}
              checked={this.props.checked}
              rows="6"
              containerClassName=""
              errorContainerClassName="is-invalid-input"
              validations={this.props.validations}
              required
              autoComplete="off"
              aria-describedby={this.props.ariaDescribedby}
              data-input-type={this.props.dataInputType}
            />
            <span className="help-message">{this.props.helpMessage}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={this.props.containerClassName}>
        <div className="form__element-wrapper">
          <label className="form__label" htmlFor={this.props.id}>
            <span>{this.props.label}</span>{" "}
            {this.props.abbr && <abbr title="Obligatoriskt">*</abbr>}
          </label>
          <Validation.components.Input
            className={this.props.className}
            id={this.props.id}
            type={this.props.type}
            name={this.props.name}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            value={this.props.value}
            checked={this.props.checked}
            containerClassName=""
            errorContainerClassName="is-invalid-input"
            validations={this.props.validations}
            autoComplete={this.props.autocomplete || "off"}
            required={this.props.required}
            aria-describedby={this.props.ariaDescribedby}
            data-input-type={this.props.dataInputType}
          />

          <span className="help-message">{this.props.helpMessage}</span>
        </div>
      </div>
    );
  }
}

export default InputReactWithBootstrap;
