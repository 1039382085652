import React from 'react';
import Validation from 'react-validation';
import validator from '../validator.js';
 
Object.assign(Validation.rules, {
    // Key name maps the rule 
    required: {
        // Function to validate value 
        // NOTE: value might be a number -> force to string 
        rule: value => {
            return value.toString().trim();
        },
        // Function to return hint 
        // You may use current value to inject it in some way to the hint 
        hint: value => {
            return <span className='form__error-text is-visible'>Obligatoriskt fält.</span>
        }
    },
    email: {
        // Example usage with external 'validator' 
        rule: value => {
            return validator.isEmail(value);
        },
        hint: value => {
            return <span className='form__error-text is-visible'>{value} är inte en e-postadress.</span>
        }
    },
    emailNotRequired: {
        // Example usage with external 'validator' 
        rule: value => {
            return !value.toString().trim() || validator.isEmail(value);
        },
        hint: value => {
            return <span className='form__error-text is-visible'>{value} är inte en e-postadress.</span>
        }
    },
    // This example shows a way to handle common task - compare two fields for equality 
    password: {
        // rule function can accept argument: 
        // components - components registered to Form mapped by name 
        rule: (value, components) => {            
            const password = components.password.state;
            const passwordConfirm = components.passwordConfirm.state;

            if(password.value.length==0 && passwordConfirm.value.length==0) {
                return true;
            }                
            return password.value === passwordConfirm.value;
        },
        hint: () => <span className="form__error-text is-visible">Lösenorden måste stämma överens.</span>
    },
    passwordStrength: {        
        rule: value => {
            return validator.isPasswordStrengthOk(value);
        },
        hint: value => {
            return <span className='form__error-text is-visible'>Lösenordet uppfyller inte lösenordskriterierna.</span>
        }
    },   
    alpha: {
        rule: value => validator.isAlpha(value),
        hint: () => (
            <span className="form__error-text is-visible">
                Innehåller ogiltiga tecken.
            </span>
        )
    },
    numeric: {
        rule: value => validator.isNumeric(value),
        hint: () => (
            <span className="form__error-text is-visible">
                Innehåller ogiltiga tecken.
            </span>
        )
    },
    numericNotRequired: {
        rule: value => {
            return !value.toString().trim() || validator.isNumeric(value);
        },
        hint: () => (
            <span className="form__error-text is-visible">
                Innehåller ogiltiga tecken.
            </span>
        )
    },
    phone: {
        rule: value => {
            if (value.length === 0) {
                return true;
            }

            return validator.isPhone(value);
        },
        hint: () => (
            <span className="form__error-text is-visible">
                Skriv in med siffror och ett bindestreck(-) efter riktnumret.
            </span>
        )
    },
    zip: {
        rule: value => {
            if (value.length === 0) {
                return true;
            }

            var regex = new RegExp("^[0-9]{3}[ ]?[0-9]{2}$");
            return regex.test(value);
        },
        hint: () => (
            <span className="form__error-text is-visible">
                Innehåller ogiltiga tecken.
            </span>
        )
    },
    url: {
        rule: value => {
            return validator.isURL(value);
        },
        hint: () => (
            <span className="form__error-text is-visible">
                Innehåller ogiltiga tecken.
            </span>
        )
    },
    // Define API rule to show hint after API error response 
    api: {
        // We don't need the rule here because we will call the 'showError' method by hand on API error 
        hint: value => (
            <button
                className="form__error-text is-visible"
            >
                API Error on "{value}" value. Focus to hide.
            </button>
        )
    }
});