import React from "react";
import ReactDOM from "react-dom";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import Validation from "react-validation";
import { isEmail, isAlpha, isNumeric } from "../../validator";

require("es6-promise").polyfill();

export default class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMail: true,
      mail: window.login.email || "",
      newMail: "",
      mailFocus: "",
      newMailFocus: "",
      mailValid: "",
      newMailValid: "",
      password: "",
      passwordFocus: "",
      passwordValid: "",
      firstName: "",
      lastName: "",
      firstNameFocus: "",
      lastNameFocus: "",
      firstNameValid: "",
      lastNameValid: "",
      phoneNumber: "",
      phoneNumberFocus: "",
      phoneNumberValid: "",
      regionOption: 0,
      errorMessage: "",
      errorLoginMessage: "",
      infoMessage: "",
      dropDownMunicipality: [],
      activeMunicipalityId: "",
      dropDownCountryCouncil: [],
      activeCountryCouncilId: "",
    };

    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleNewMailChange = this.handleNewMailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleLogInClick = this.handleLogInClick.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleMailFocus = this.handleMailFocus.bind(this);
    this.handleFirstNameFocus = this.handleFirstNameFocus.bind(this);
    this.handleLastNameFocus = this.handleLastNameFocus.bind(this);
    this.handleNewMailFocus = this.handleNewMailFocus.bind(this);
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handlePhoneNumberFocus = this.handlePhoneNumberFocus.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleRegisterUser = this.handleRegisterUser.bind(this);
  }

  componentWillMount() {
    this.getMunicipalities();
    this.getCountyConcils();
    this.getGovernmentOrgs();
  }

  render() {
    return (
      <div>
        <section className="page-wrapper">
          <div className="page-wrapper__layout">
            <div className="row">
              <div className="col-md-7">
                <Validation.components.Form
                  ref={(c) => {
                    this.form = c;
                  }}
                  onSubmit={this.handleLogInClick}
                >
                  <div className="margin-bottom-30">
                    <h2>Logga in som annonsör</h2>
                    <p>
                      Här loggar du in med dina inloggningsuppgifter och
                      redigerar annonser för din organisation/myndighet. Är du
                      ny användare som saknar inloggningsuppgifter fyller du i
                      formuläret för att registrera dig.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      {this.state.errorMessage && (
                        <div className="c-alert c-alert--danger">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<p>${this.state.errorMessage}<p>`,
                            }}
                          />
                        </div>
                      )}
                      {window.login.errorList &&
                        window.login.errorList.length > 0 && (
                          <div className="c-alert c-alert--info">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            {window.login.errorList.map((message, index) => {
                              return <span key={index}>{message}</span>;
                            })}
                          </div>
                        )}
                      <InputReactWithBootstrap
                        onChange={this.handleMailChange}
                        onFocus={this.handleMailFocus}
                        onBlur={this.handleUnfocus}
                        label="E-post"
                        value={this.state.mail}
                        type="input"
                        name="email"
                        className="form__input"
                        id="mail"
                        focus={this.state.mailFocus}
                        valid={this.state.mailValid}
                        validations={["required", "email"]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handlePasswordChange}
                        onFocus={this.handlePasswordFocus}
                        onBlur={this.handleUnfocus}
                        label="Lösenord"
                        value={this.state.password}
                        type="password"
                        name="password"
                        className="form__input"
                        id="password"
                        focus={this.state.passwordFocus}
                        valid={this.state.passwordValid}
                        validations={["required"]}
                      />
                      {window.login.antiforgery && (
                        <input
                          value={window.login.antiforgery}
                          name="__RequestVerificationToken"
                          type="hidden"
                        />
                      )}

                      {window.login.returnUrl && (
                        <input
                            value={window.login.returnUrl}
                            name="returnUrl"
                            type="hidden"
                          />
                      )}

                      <a
                        href={`${window.login.apiurl}resetpassword`}
                        className="login-page__link login-page__link--forgot-password"
                      >
                        Glömt lösenord?
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <div id="login">
                        <input
                          type="submit"
                          value="Logga in"
                          className="btn uhm-petrol"
                          tabIndex="0"
                        />
                      </div>
                      <div className="login-page__link--new-user">
                        <a
                          href={`${window.login.apiurl}register`}
                          className="login-page__link"
                        >
                          Ny användare? Registrera dig här.
                        </a>
                      </div>
                    </div>
                  </div>
                </Validation.components.Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  //Handlers --------------------------------------------------------------------------------------------------
  handleRegionChange(val) {
    if (this.state.regionOption === 0) {
      if (val) {
        this.setState({
          activeMunicipalityId: val.value,
        });
      } else {
        this.setState({
          activeMunicipalityId: "",
        });
      }
    }
    if (this.state.regionOption === 1) {
      if (val) {
        this.setState({
          activeCountryCouncilId: val.value,
        });
      } else {
        this.setState({
          activeCountryCouncilId: "",
        });
      }
    }
    if (this.state.regionOption === 2) {
      if (val) {
        this.setState({
          activeGovernmentOrgId: val.value,
        });
      } else {
        this.setState({
          activeGovernmentOrgId: "",
        });
      }
    }
  }

  handleOptionChange(changeEvent) {
    var radioBool = 0;
    if (changeEvent.target.value == "Landsting/Region") {
      radioBool = 1;
    }
    if (changeEvent.target.value == "Statlig myndighet") {
      radioBool = 2;
    }
    this.setState({
      regionOption: radioBool,
    });
  }

  handleMailChange(inputMail) {
    this.setState({
      mail: inputMail.target.value,
    });
    if (this.state.mail && !this.state.mailFocus) {
      this.setState({
        mailValid: "-valid",
      });
    } else {
      this.setState({
        mailValid: "",
      });
    }
  }

  handleNewMailChange(inputNewMail) {
    this.setState({
      newMail: inputNewMail.target.value,
    });
    if (this.state.newMail && !this.state.newMailFocus) {
      this.setState({
        newMailValid: "-valid",
      });
    } else {
      this.setState({
        newMailValid: "",
      });
    }
  }

  handlePasswordChange(inputPassword) {
    this.setState({
      password: inputPassword.target.value,
    });
    if (this.state.password && !this.state.passwordFocus) {
      this.setState({
        passwordValid: "-valid",
      });
    } else {
      this.setState({
        passwordValid: "",
      });
    }
  }

  handleFirstNameChange(inputFirstName) {
    this.setState({
      firstName: inputFirstName.target.value,
    });
    if (this.state.firstName && !this.state.firstNameFocus) {
      this.setState({
        firstNameValid: "-valid",
      });
    } else {
      this.setState({
        firstNameValid: "",
      });
    }
  }

  handleLastNameChange(inputLastName) {
    this.setState({
      lastName: inputLastName.target.value,
    });
    if (this.state.lastName && !this.state.lastNameFocus) {
      this.setState({
        lastNameValid: "-valid",
      });
    } else {
      this.setState({
        lastNameValid: "",
      });
    }
  }

  handleLogInClick(event) {
    event.preventDefault();

    if (isEmail(this.state.mail) && this.state.password) {
      this.isUser(this.state.mail, this.state.password);
    } else {
      this.form.validateAll();
    }
  }

  handleForgotPasswordClick() {
    if (isEmail(this.state.mail)) {
      this.resetPassword(this.state.mail);
    } else {
      this.handleLoginError(window.messages.login.forgotPassword);
      this.form.validateAll();
    }
  }

  handlePhoneNumberChange(inputPhoneNumber) {
    this.setState({
      phoneNumber: inputPhoneNumber.target.value,
    });
    if (this.state.phoneNumber && !this.state.phoneNumberFocus) {
      this.setState({
        phoneNumberValid: "-valid",
      });
    } else {
      this.setState({
        phoneNumberValid: "",
      });
    }
  }

  handleRegisterUser(event) {
    event.preventDefault();
    if (
      isEmail(this.state.newMail) &&
      isAlpha(this.state.firstName) &&
      isAlpha(this.state.lastName) &&
      isNumeric(this.state.phoneNumber) &&
      this.state.regionOption != -1
    ) {
      let newUser = {
        mail: this.state.newMail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        region: this.state.regionOption,
        countryCouncilId: this.state.activeCountryCouncilId,
        municipalityId: this.state.activeMunicipalityId,
        governmentOrgId: this.state.activeGovernmentOrgId,
      };
      this.saveData(newUser);
    } else {
      this.formNewUser.validateAll();
    }
  }

  handleMailFocus() {
    this.setState({
      mailFocus: "-focus",
      newMailFocus: "",
      passwordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleNewMailFocus() {
    this.setState({
      newMailFocus: "-focus",
      mailFocus: "",
      passwordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handlePasswordFocus() {
    this.setState({
      passwordFocus: "-focus",
      mailFocus: "",
      newMailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleFirstNameFocus() {
    this.setState({
      firstNameFocus: "-focus",
      mailFocus: "",
      newMailFocus: "",
      passwordFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handleLastNameFocus() {
    this.setState({
      lastNameFocus: "-focus",
      mailFocus: "",
      newMailFocus: "",
      passwordFocus: "",
      firstNameFocus: "",
      phoneNumberFocus: "",
    });
  }

  handlePhoneNumberFocus() {
    this.setState({
      phoneNumberFocus: "-focus",
      mailFocus: "",
      newMailFocus: "",
      passwordFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
    });
  }

  handleUnfocus() {
    this.setState({
      mailFocus: "",
      passwordFocus: "",
      newMailFocus: "",
      firstNameFocus: "",
      lastNameFocus: "",
      phoneNumberFocus: "",
    });

    if (
      this.state.newMail &&
      isEmail(this.state.newMail) &&
      this.state.loadMail
    ) {
      this.getInformationByMail(this.state.newMail);
    }
  }

  handleInfo(infoMessage) {
    this.setState({
      infoMessage,
    });
  }
  //db --------------------------------------------------------------------------------------------------

  isUser(user, password) {
    fetch(window.login.apiurl + "Validate", {
      body: JSON.stringify({
        email: user,
        password: password,
        __RequestVerificationToken: window.login.antiforgery,
        returnUrl: window.login.returnUrl,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsError || result.status >= 300) {
          this.setState({
            errorMessage: result.Error
              ? result.Error
              : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
          });
        } else {
          if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({
              event: "lovLogin",
            });
          }
          window.location.href = result.RedirectTo;
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
        });
      });
  }

  resetPassword(user) {
    fetch(window.login.apiurl + "ResetPassword", {
      body: JSON.stringify({
        email: user,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
    })
      .then((response) => {
        if (response.status >= 300) {
          // console.log('ErrorResponse', response);
        }
        return response.json();
      })
      .then((json) => {
        if (json.IsError) {
          // Handle the error here
          this.handleLoginError(json.Error);
          return json;
        } else {
          this.handleLoginError(json.Info);
        }
      });
  }

  getMunicipalities() {
    fetch(window.login.apiurl + "Municipalities/", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status >= 300) {
          console.log("ErrorResponse", response);
        }
        return response.json();
      })
      .then((data) => {
        let dropdown = data.map((option) => {
          return { value: option.Value, label: option.Text };
        });
        this.setState({
          dropDownMunicipality: dropdown,
        });
        return data;
      });
  }

  getCountyConcils() {
    fetch(window.login.apiurl + "CountyCouncils/", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status >= 300) {
          console.log("ErrorResponse", response);
        }
        return response.json();
      })
      .then((data) => {
        let dropdown = data.map((option) => {
          return { value: option.Value, label: option.Text };
        });
        this.setState({
          dropDownCountryCouncil: dropdown,
        });
        return data;
      });
  }

  getGovernmentOrgs() {
    fetch(window.login.apiurl + "GovernmentOrgs/", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status >= 300) {
          console.log("ErrorResponse", response);
        }
        return response.json();
      })
      .then((data) => {
        let dropdown = data.map((option) => {
          return { value: option.Value, label: option.Text };
        });
        this.setState({
          dropDownGovernmentOrg: dropdown,
        });
        return data;
      });
  }

  getInformationByMail(mail) {
    // this.setState({
    //     loadMail: false
    // });
    fetch(window.login.apiurl + "WhiteList/?email=" + mail, {
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        if (response.status >= 300) {
          console.log("ErrorResponse", response);
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.regionOption == "Kommun") {
            this.setState({
              regionOption: 0,
              activeMunicipalityId: data.regionOptionId,
            });
          } else if (data.regionOption == "Landsting") {
            this.setState({
              regionOption: 1,
              activeCountryCouncilId: data.regionOptionId,
            });
          } else {
            this.setState({
              regionOption: 2,
              activeGovernmentOrgId: data.regionOptionId,
            });
          }
          $("#collapse-additional-info").collapse("hide");
        } else {
          $("#collapse-additional-info").collapse("show");
        }
        return data;
      });
  }

  //post
  saveData(form) {
    //Check form no null or undefined value
    if (!!form) {
      this.handleInfo("Vänta");
      var _this = this;
      fetch(window.login.apiurl + "Register", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.IsError || result.status >= 300) {
            this.setState({
              errorMessage: result.Error
                ? result.Error
                : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
            });
          } else {
            window.location.href = result.RedirectTo;
          }
        })
        .catch((error) => {
          this.setState({
            errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
          });
        });
    }
  }
}

const loginDiv = document.getElementById("login");
if (loginDiv) {
  ReactDOM.render(<LogIn />, loginDiv);
}
