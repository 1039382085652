import jQuery from 'jquery';

let component_filterCheckbox = {

    init() {
        
        $('.checkbox-custom__container input[type="checkbox"]:checked').each(function(){
            $(this).closest('.collapse').collapse('show');
        });
    }

};

export default component_filterCheckbox;