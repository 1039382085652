/**
 * MultiselectDropdownOption
 */

import React from "react";

/** Multiselect dropdown component. */
export default class MultiselectDropdownOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: this.props.isChecked,
    };
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
  }

  render() {
    return (
      <label
        htmlFor={this.props.id}
        className="multi-select-dropdown__dropdown__option"
      >
        <input
          type="checkbox"
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          checked={this.state.isChecked}
          onChange={() => {
            this.props.onChange(this.props.value);
            this.setState({ isChecked: !this.state.isChecked });
          }}
          disabled={this.props.disabled}
        />
        <span className="multi-select-dropdown__dropdown__option__text">
          {this.props.children}
        </span>
      </label>
    );
  }

  handleToggleDropdown() {}
}
